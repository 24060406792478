/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents medication prior authorization list item data.
 */
export type MedicationPriorAuthorizationListItemResponse = {
    /**
     * The ID of the PA.
     */
    id: string;
    /**
     * The ID of the user the PA was done for.
     */
    userId: string;
    /**
     * The first name of the User the PA is for
     */
    userFirstName: string;
    /**
     * The last name of the User the PA is for
     */
    userLastName: string;
    /**
     * The medication name the PA is for
     */
    medicationName: string;
    /**
     * The the ID of the corresponding initial PA
     */
    appealFor?: string;
    /**
     * Indicates, whether this is an Appeal or an Initial PA, and which stage of the Appeal
     */
    type: MedicationPriorAuthorizationListItemResponse.type;
    /**
     * The date when the PA was initiated / or appealed
     */
    initiatedAt: string;
    /**
     * The date when the PA was updated
     */
    lastUpdated?: string;
    /**
     * The date until the PA is valid
     */
    validUntil?: string;
    /**
     * A url that leads to the PA on the Cover My Meds Website
     */
    coverMyMedsUrl?: string;
    /**
     * Reflects the status of the PA
     */
    status: MedicationPriorAuthorizationListItemResponse.status;
};

export namespace MedicationPriorAuthorizationListItemResponse {

    /**
     * Indicates, whether this is an Appeal or an Initial PA, and which stage of the Appeal
     */
    export enum type {
        INITIAL = 'INITIAL',
        APPEAL_1 = 'APPEAL_1',
        APPEAL_2 = 'APPEAL_2',
        APPEAL_3 = 'APPEAL_3',
        APPEAL_4 = 'APPEAL_4',
        APPEAL_5 = 'APPEAL_5',
    }

    /**
     * Reflects the status of the PA
     */
    export enum status {
        WAITING_ON_PHARMACY = 'WAITING_ON_PHARMACY',
        NEW = 'NEW',
        CREATED = 'CREATED',
        SENT_TO_PLAN = 'SENT_TO_PLAN',
        NOT_SENT_TO_PLAN = 'NOT_SENT_TO_PLAN',
        OUTCOME_UNKNOWN = 'OUTCOME_UNKNOWN',
        PATIENT_NOT_COVERED_BY_PLAN = 'PATIENT_NOT_COVERED_BY_PLAN',
        APPROVED = 'APPROVED',
        DENIED = 'DENIED',
    }


}

