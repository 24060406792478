/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessCreateFileResponse } from '../models/SuccessCreateFileResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FileControllerService {

    /**
     * Uploads a file to be persisted.
     * Files can be uploaded and they are persisted so that they can be retrieved later. Uponupload, each file will get assigned a unique UUID which can later be used for retrieval. Filesare immutable and are assigned to the user who is uploading them.
     * @param requestBody The file content.
     * @param xNineamHealthFileAttributes A JSON object containing file metadata attributes.
     * @returns SuccessCreateFileResponse The file was uploaded successfully; the file ID is returned in the response body.
     * @throws ApiError
     */
    public static createFile(
        requestBody: any,
        xNineamHealthFileAttributes?: string,
    ): CancelablePromise<SuccessCreateFileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/files',
            headers: {
                'X-NineamHealth-File-Attributes': xNineamHealthFileAttributes,
            },
            body: requestBody,
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Downloads a file.
     * Allows the client to download a file, identified by its id (UUID).
     * @param id The id of the file to be downloaded
     * @returns any The file content.
     * @throws ApiError
     */
    public static downloadFile(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/files/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `No file with the given ID was found, or the requesting user has no permission to download the file.`,
            },
        });
    }

    /**
     * Provides a file preview for a given file, identified by its id (UUID).
     * @param id The id of the file to be previewed.
     * @param width
     * @returns any OK
     * @throws ApiError
     */
    public static downloadFilePreview(
        id: string,
        width: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/files/{id}/preview/{width}',
            path: {
                'id': id,
                'width': width,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Provides a file preview for a given file, identified by its id (UUID).
     * @param id The id of the file to be previewed.
     * @param width
     * @param height
     * @returns any OK
     * @throws ApiError
     */
    public static downloadFilePreview1(
        id: string,
        width: number,
        height: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/files/{id}/preview/{width}/{height}',
            path: {
                'id': id,
                'width': width,
                'height': height,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Downloads a file using jwt token.
     * Allows the client to download a file, identified by its jwtToken (String).
     * @param jwtToken The id of the file to be downloaded
     * @returns any The file content.
     * @throws ApiError
     */
    public static downloadFileByJwtToken(
        jwtToken: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/files/token/{jwtToken}',
            path: {
                'jwtToken': jwtToken,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `No file with the given ID was found, or the requesting user has no permission to download the file.`,
            },
        });
    }

}
