/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information returned after a coupon is created or updated.
 */
export type PharmacyCouponResponse = {
    /**
     * CardHolder ID of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    cardHolderId: string;
    /**
     * Group Number of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    rxGrp?: string;
    /**
     * Status of current Insurance/Coupon.
     */
    status: PharmacyCouponResponse.status;
    /**
     * The date when a insurance is effective from.
     */
    effectiveStartDate?: string;
    /**
     * The date when this insurance is effective until.
     */
    effectiveEndDate?: string;
    /**
     * The ID of uploaded file which is front of the card.
     */
    frontPhotoFileId?: string;
    /**
     * The ID of uploaded file which is back of the card.
     */
    backPhotoFileId?: string;
    /**
     * Bank Identification Number of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    rxBin: string;
    /**
     * Processor Control Number of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    rxPcn?: string;
    /**
     * ID of user who stored insurance/coupon details.
     */
    createdBy: string;
    /**
     * ID of user who modified insurance/coupon details.
     */
    updatedBy: string;
    /**
     * The date/time when this object was created.
     */
    createdAt: string;
    /**
     * The date/time when this object was updated.
     */
    lastUpdated: string;
    /**
     * The medication name covered fully or partially by a manufacturer coupon.
     */
    medicationName: string;
    /**
     * The phone number of the coupon customer service.
     */
    customerServiceNumber?: string;
};

export namespace PharmacyCouponResponse {

    /**
     * Status of current Insurance/Coupon.
     */
    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }


}

