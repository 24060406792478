/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 9am-specific information about a consultation action.
 */
export type CarePlanActionResponseItem = {
    /**
     * The consultation's action ID.
     */
    id: string;
    /**
     * The consultation's action type.
     */
    type: CarePlanActionResponseItem.type;
};

export namespace CarePlanActionResponseItem {

    /**
     * The consultation's action type.
     */
    export enum type {
        ADD_STANDING_LAB_ORDER = 'AddStandingLabOrder',
        DO_LAB_TEST = 'DoLabTest',
        TAKE_MEDICATION = 'TakeMedication',
        RENEW_STANDING_LAB_ORDER = 'RenewStandingLabOrder',
        ADD_MEDICATION = 'AddMedication',
        ADJUST_MEDICATION = 'AdjustMedication',
        STOP_MEDICATION = 'StopMedication',
        RENEW_MEDICATION = 'RenewMedication',
        CONTINUE_MEDICATION = 'ContinueMedication',
        DOCUMENT_MEDICATION = 'DocumentMedication',
        ADD_SUPPLY = 'AddSupply',
        ADJUST_SUPPLY = 'AdjustSupply',
        CONTINUE_SUPPLY = 'ContinueSupply',
        STOP_SUPPLY = 'StopSupply',
        RENEW_SUPPLY = 'RenewSupply',
        ANSWER_QUESTION = 'AnswerQuestion',
        TAKE_CONSULTATION_NOTES = 'TakeConsultationNotes',
        ADD_SERVICE_REQUEST = 'AddServiceRequest',
        CONTINUE_SERVICE_REQUEST = 'ContinueServiceRequest',
        RENEW_SERVICE_REQUEST = 'RenewServiceRequest',
        CONTINUE_DIAGNOSIS = 'ContinueDiagnosis',
        ADD_DIAGNOSIS = 'AddDiagnosis',
        STOP_DIAGNOSIS = 'StopDiagnosis',
    }


}

