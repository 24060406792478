/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An optional object, providing the actual result.
 */
export type DeviceItem = {
    /**
     * Internal id for the device
     */
    id?: string;
    /**
     * Type of device
     */
    type: DeviceItem.type;
    /**
     * Device model name
     */
    model?: string;
    /**
     * Indicates whether the device is connected by a cellular connection
     */
    connectedByCellular: boolean;
    /**
     * If the device was automatically added through a prescription, the id will be filled
     */
    prescriptionId?: string | null;
    /**
     * Creation date of the device entry
     */
    createdAt?: string;
    /**
     * Last update date of the device entry
     */
    updatedAt?: string;
};

export namespace DeviceItem {

    /**
     * Type of device
     */
    export enum type {
        BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
        GLUCOMETER = 'GLUCOMETER',
        CGM = 'CGM',
        WEIGHT_SCALE = 'WEIGHT_SCALE',
        TAPE_MEASURE = 'TAPE_MEASURE',
        MOBILE_PHONE = 'MOBILE_PHONE',
    }


}

