/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The credentials used to login a user with Google, starting a session.
 */
export type GoogleLoginRequest = {
    /**
     * The Google ID token, as received from Google.
     */
    googleIdToken: string;
    /**
     * An optional role that the client assumes during this session.
     */
    role?: GoogleLoginRequest.role;
    /**
     * A name for the session, e.g., that identifies the client device.
     */
    sessionName: string;
    /**
     * The one-time password for Multi-Factor-Authentication.
     */
    mfaToken?: string;
};

export namespace GoogleLoginRequest {

    /**
     * An optional role that the client assumes during this session.
     */
    export enum role {
        ANONYMOUS = 'ANONYMOUS',
        CHANGE_PASSWORD = 'CHANGE_PASSWORD',
        API_DOCUMENTATION = 'API_DOCUMENTATION',
        USER = 'USER',
        STAFF_VIEW_ONLY = 'STAFF_VIEW_ONLY',
        STAFF = 'STAFF',
        STAFF_ADMIN_ACCESS = 'STAFF_ADMIN_ACCESS',
        STAFF_CUSTOMER_SUPPORT = 'STAFF_CUSTOMER_SUPPORT',
        STAFF_DIABETES_EDUCATOR = 'STAFF_DIABETES_EDUCATOR',
        STAFF_PROVIDER = 'STAFF_PROVIDER',
        STAFF_PHYSICIAN = 'STAFF_PHYSICIAN',
        STAFF_PHYSICIAN_ASSISTANT = 'STAFF_PHYSICIAN_ASSISTANT',
        STAFF_NURSE_PRACTITIONER = 'STAFF_NURSE_PRACTITIONER',
        DEVELOPER = 'DEVELOPER',
        SUPER_ADMIN = 'SUPER_ADMIN',
    }


}

