/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Response providing details for an individual user.
 */
export type AdminUserDetailsResponse = {
    id?: string;
    createdAt?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    email?: string;
    emailVerifiedAt?: string;
    lastConsultationStatus?: AdminUserDetailsResponse.lastConsultationStatus;
    subscriptionStatus?: AdminUserDetailsResponse.subscriptionStatus;
    subscriptionStartDate?: string;
    sex?: string;
    activePayerId?: string;
    activeEmployerId?: string;
    programMemberships?: Array<string>;
    /**
     * The user's analytics token, used in external services like Mixpanel.
     */
    analyticsToken?: string;
    /**
     * The user's ID in Zendesk.
     */
    zendeskUserId?: number;
    /**
     * The user's customer ID in Stripe.
     */
    stripeCustomerId?: string;
    /**
     * The user's ID in Apero.
     */
    aperoPatientId?: string;
};

export namespace AdminUserDetailsResponse {

    export enum lastConsultationStatus {
        DRAFT = 'DRAFT',
        ACTIVE = 'ACTIVE',
        COMPLETED = 'COMPLETED',
        ISSUE = 'ISSUE',
        REJECTED = 'REJECTED',
        CANCELLED = 'CANCELLED',
        WAITING_FOR_CARE_TEAM = 'WAITING_FOR_CARE_TEAM',
    }

    export enum subscriptionStatus {
        DRAFT = 'DRAFT',
        IN_REVIEW = 'IN_REVIEW',
        ACTIVE = 'ACTIVE',
        REJECTED = 'REJECTED',
        FINISHED = 'FINISHED',
        PAUSED = 'PAUSED',
    }


}

