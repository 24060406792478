/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegisterPhoneNumberRequest } from '../models/RegisterPhoneNumberRequest';
import type { SuccessCollectionPhoneNumber } from '../models/SuccessCollectionPhoneNumber';
import type { SuccessObject } from '../models/SuccessObject';
import type { SuccessRegisterPhoneNumberResponse } from '../models/SuccessRegisterPhoneNumberResponse';
import type { VerifyPhoneNumberRequest } from '../models/VerifyPhoneNumberRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SmsControllerService {

    /**
     * Returns a user's registered phone numbers.
     * Each number consists of an actual number in E164 format, plus a boolean indicating whether the number has been verified through a code.
     * @returns SuccessCollectionPhoneNumber A list of phone numbers is returned.
     * @throws ApiError
     */
    public static listPhoneNumbers(): CancelablePromise<SuccessCollectionPhoneNumber> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/sms/numbers',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Registers a phone number for the user.
     * This will initiate a verification workflow by sending a SMS to the phone, containing a 6-digit verification code. This code must be provided via the `/sms/numbers/verify` endpoint to confirm the number. Verification will be skipped if the `verify` attribute is set to `false`.
     * @param requestBody
     * @returns SuccessRegisterPhoneNumberResponse The phone verification process has been initiated.
     * @throws ApiError
     */
    public static registerPhoneNumber(
        requestBody: RegisterPhoneNumberRequest,
    ): CancelablePromise<SuccessRegisterPhoneNumberResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/sms/numbers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Confirms a user's ownership of a given phone number.
     * This is done by providing the phone number, the verification token (as returned by the registration request), and the 6-digit code that was sent to the phone number via SMS.
     * @param requestBody
     * @returns SuccessObject The phone number has been verified and is ready to be used.
     * @throws ApiError
     */
    public static verifyPhoneNumber(
        requestBody: VerifyPhoneNumberRequest,
    ): CancelablePromise<SuccessObject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/sms/numbers/verify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
