/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Models parameters that can be used to collect a payment method from the user. The interpretation of the contained parameters depends on the payment provider, identified by its name.
 */
export type InitiatePaymentMethodCollectionResponse = {
    /**
     * The identifier (name) of the payment provider that is associated to this payment method.
     */
    providerName: InitiatePaymentMethodCollectionResponse.providerName;
    /**
     * A map of parameters that can be used for collecting the payment method information.
     */
    parameters?: Record<string, string>;
};

export namespace InitiatePaymentMethodCollectionResponse {

    /**
     * The identifier (name) of the payment provider that is associated to this payment method.
     */
    export enum providerName {
        STRIPE = 'STRIPE',
    }


}

