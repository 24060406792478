/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data to change medication prior auth status.
 */
export type UpdateMedicationPriorAuthorizationStatusRequest = {
    /**
     * Reflects the status of the PA
     */
    status: UpdateMedicationPriorAuthorizationStatusRequest.status;
    /**
     * Status description for the PA
     */
    statusDescription?: string;
    /**
     * Status comment for the PA
     */
    comment?: string;
    /**
     * The date until the PA is valid
     */
    validUntil?: string;
    /**
     * Document ids related with status change of the PA
     */
    attachments?: Array<string>;
    /**
     * The date when the PA status was updated
     */
    updatedAt: string;
};

export namespace UpdateMedicationPriorAuthorizationStatusRequest {

    /**
     * Reflects the status of the PA
     */
    export enum status {
        WAITING_ON_PHARMACY = 'WAITING_ON_PHARMACY',
        NEW = 'NEW',
        CREATED = 'CREATED',
        SENT_TO_PLAN = 'SENT_TO_PLAN',
        NOT_SENT_TO_PLAN = 'NOT_SENT_TO_PLAN',
        OUTCOME_UNKNOWN = 'OUTCOME_UNKNOWN',
        PATIENT_NOT_COVERED_BY_PLAN = 'PATIENT_NOT_COVERED_BY_PLAN',
        APPROVED = 'APPROVED',
        DENIED = 'DENIED',
    }


}

