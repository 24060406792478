/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AdminShipmentItemResponse } from './AdminShipmentItemResponse';

/**
 * Contains information about a shipment.
 */
export type AdminShipmentResponse = {
    id: string;
    status: AdminShipmentResponse.status;
    priority: AdminShipmentResponse.priority;
    createdAt: string;
    deliveredAt?: string;
    items: Array<AdminShipmentItemResponse>;
    port?: AdminShipmentResponse.port;
    portId?: string;
    trackingUrl?: string;
    message?: string;
    additionalData?: Record<string, any>;
};

export namespace AdminShipmentResponse {

    export enum status {
        PENDING = 'PENDING',
        INITIATED = 'INITIATED',
        REVIEWING = 'REVIEWING',
        TRANSIT = 'TRANSIT',
        DELIVERED = 'DELIVERED',
        RETURNED = 'RETURNED',
        ERROR = 'ERROR',
    }

    export enum priority {
        HIGHEST = 'HIGHEST',
        HIGH = 'HIGH',
        REGULAR = 'REGULAR',
    }

    export enum port {
        SMARTMETERRPM = 'SMARTMETERRPM',
        TRUEPILL = 'TRUEPILL',
        ZENDESK = 'ZENDESK',
        MANUAL = 'MANUAL',
    }


}

