/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Input for setting a default payment method.
 */
export type SetDefaultPaymentMethodRequest = {
    /**
     * The name of the payment provider.
     */
    paymentProviderName: SetDefaultPaymentMethodRequest.paymentProviderName;
    /**
     * The identifier of the default payment method.
     */
    paymentMethodId: string;
};

export namespace SetDefaultPaymentMethodRequest {

    /**
     * The name of the payment provider.
     */
    export enum paymentProviderName {
        STRIPE = 'STRIPE',
    }


}

