/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DailyDistribution } from './DailyDistribution';

/**
 * The daily dosage of the medication
 */
export type MedicationDailyDosage = {
    medication?: MedicationDailyDosage.medication;
    details?: string;
    dailyDistribution?: DailyDistribution;
    orderedTimesDaily?: number;
    calculatedTimesDaily?: number;
    multiplication?: number;
};

export namespace MedicationDailyDosage {

    export enum medication {
        METFORMIN = 'METFORMIN',
        METFORMIN_ER = 'METFORMIN_ER',
        GLIPIZIDE = 'GLIPIZIDE',
        GLIPIZIDE_XL = 'GLIPIZIDE_XL',
        GLIMEPIRIDE = 'GLIMEPIRIDE',
        PIOGLITAZONE = 'PIOGLITAZONE',
        METAGLIP = 'METAGLIP',
        LISINOPRIL = 'LISINOPRIL',
        LOSARTAN = 'LOSARTAN',
        HYDROCHLOROTHIAZIDE = 'HYDROCHLOROTHIAZIDE',
        AMLODIPINE = 'AMLODIPINE',
        METOPROLOL_TARTRATE_LOPRESSOR = 'METOPROLOL_TARTRATE_LOPRESSOR',
        METOPROLOL_SUCCINATE_TOPROL_XL = 'METOPROLOL_SUCCINATE_TOPROL_XL',
        LOVAZA = 'LOVAZA',
        GEMFIBROZIL = 'GEMFIBROZIL',
        ATORVASTATIN = 'ATORVASTATIN',
        ROSUVASTATIN = 'ROSUVASTATIN',
        LOVASTATIN = 'LOVASTATIN',
        SIMVASTATIN = 'SIMVASTATIN',
        PRAVASTATIN = 'PRAVASTATIN',
        EZETIMIBE = 'EZETIMIBE',
        FENOFIBRATE = 'FENOFIBRATE',
        RED_YEAST_RICE = 'RED_YEAST_RICE',
        EXENATIDE = 'EXENATIDE',
        LIRAGLUTIDE = 'LIRAGLUTIDE',
        DULAGLUTIDE = 'DULAGLUTIDE',
        LIXISENATIDE = 'LIXISENATIDE',
        SEMAGLUTIDE = 'SEMAGLUTIDE',
        SEMAGLUTIDE_WEGOVY = 'SEMAGLUTIDE_WEGOVY',
        SEMAGLUTIDE_RYBELSUS = 'SEMAGLUTIDE_RYBELSUS',
        TIRZEPATIDE_MOUNJARO = 'TIRZEPATIDE_MOUNJARO',
        TIRZEPATIDE_ZEPBOUND = 'TIRZEPATIDE_ZEPBOUND',
        RYBELSUS = 'RYBELSUS',
        SYNJARDY = 'SYNJARDY',
        SYNJARDY_XR = 'SYNJARDY_XR',
        CANAGLIFLOZIN = 'CANAGLIFLOZIN',
        DAPAGLIFLOZIN = 'DAPAGLIFLOZIN',
        EMPAGLIFLOZIN = 'EMPAGLIFLOZIN',
        ERTUGLIFLOZIN = 'ERTUGLIFLOZIN',
        BEXAGLIFLOZIN = 'BEXAGLIFLOZIN',
        JANUMET = 'JANUMET',
        JANUMET_XR = 'JANUMET_XR',
        SITAGLIPITIN = 'SITAGLIPITIN',
        SITAGLIPTIN_METFORMIN = 'SITAGLIPTIN_METFORMIN',
        SAXAGLIPTIN = 'SAXAGLIPTIN',
        LINAGLIPTIN = 'LINAGLIPTIN',
        ALOGLIPTIN = 'ALOGLIPTIN',
        INSULIN = 'INSULIN',
        LANTUS = 'LANTUS',
        BASAGLAR = 'BASAGLAR',
        SEMGLEE = 'SEMGLEE',
        TOUJEO = 'TOUJEO',
        LEVEMIR = 'LEVEMIR',
        TRESIBA = 'TRESIBA',
        HUMULIN = 'HUMULIN',
        NOVOLIN = 'NOVOLIN',
        HUMALOG = 'HUMALOG',
        NOVOLOG = 'NOVOLOG',
        APIDRA = 'APIDRA',
        HUMULIN_R = 'HUMULIN_R',
        NOVOLIN_R = 'NOVOLIN_R',
        CONTRAVE = 'CONTRAVE',
        PHENTERMINE = 'PHENTERMINE',
        QSYMIA = 'QSYMIA',
        TOPAMAX = 'TOPAMAX',
        WELLBUTRIN = 'WELLBUTRIN',
        XENICAL = 'XENICAL',
        LENIOLISIB = 'LENIOLISIB',
        UNKNOWN = 'UNKNOWN',
    }


}

