/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Identifies data used to start an answering session.
 */
export type StartSessionRequest = {
    /**
     * The type of the questionnaire to be answered
     */
    type: StartSessionRequest.type;
    /**
     * The ID of the questionnaire to be answered
     */
    id: string;
};

export namespace StartSessionRequest {

    /**
     * The type of the questionnaire to be answered
     */
    export enum type {
        TYPEFORM = 'TYPEFORM',
        NINEAM_HEALTH = 'NINEAM_HEALTH',
    }


}

