/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DefineUserPreferredPharmacyRequest = {
    /**
     * The id of pharmacy in provider
     */
    providerPharmacyId: string;
    /**
     * The pharmacy provider, f.e. : DOSESPOT
     */
    providerType: DefineUserPreferredPharmacyRequest.providerType;
    /**
     * The user ID, for whom the preferred pharmacy is set
     */
    userId: string;
};

export namespace DefineUserPreferredPharmacyRequest {

    /**
     * The pharmacy provider, f.e. : DOSESPOT
     */
    export enum providerType {
        DOSESPOT = 'DOSESPOT',
    }


}

