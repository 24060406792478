/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data to update a user's consent status for a given consent version.
 */
export type ConsentStatusUpdateRequest = {
    /**
     * The consent document identifier
     */
    consent: ConsentStatusUpdateRequest.consent;
    /**
     * The version number.
     */
    version: number;
    /**
     * The new status.
     */
    status: ConsentStatusUpdateRequest.status;
};

export namespace ConsentStatusUpdateRequest {

    /**
     * The consent document identifier
     */
    export enum consent {
        TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
        PRIVACY_POLICY = 'PRIVACY_POLICY',
        HEALTH_INFORMATION_RELEASE = 'HEALTH_INFORMATION_RELEASE',
        TELEHEALTH = 'TELEHEALTH',
        REFUNDS_FULFILLMENT_SHIPPING_POLICIES = 'REFUNDS_FULFILLMENT_SHIPPING_POLICIES',
        EMAIL_SMS_COMMUNICATIONS = 'EMAIL_SMS_COMMUNICATIONS',
        MEDICAL_GROUP_NOTICE_OF_PRIVACY_PRACTICES = 'MEDICAL_GROUP_NOTICE_OF_PRIVACY_PRACTICES',
    }

    /**
     * The new status.
     */
    export enum status {
        ACCEPTED = 'ACCEPTED',
        REVOKED = 'REVOKED',
    }


}

