/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents a next therapy action recommended by the AI Care Assistant
 */
export type NextActionResponse = {
    /**
     * The next action to be taken
     */
    nextAction: NextActionResponse.nextAction;
    /**
     * A freetext explainig the reasoning behind the recommended next action.
     */
    reason: string;
    /**
     * Additional details about the next action; the format depends on the next action type.
     */
    actionParameters?: Record<string, any>;
    /**
     * A textual representation of a period when the next assessment should happen.
     */
    reassessPeriod?: string;
    /**
     * A long-text explaining the reasoning behind the suggestion in more detail (Markdown version).
     */
    reasoningMarkdown: string;
    /**
     * A long-text explaining the reasoning behind the suggestion in more detail (HTML version, based on the Markdown version).
     */
    reasoningHtml: string;
};

export namespace NextActionResponse {

    /**
     * The next action to be taken
     */
    export enum nextAction {
        SEND_PATIENT_REMINDER = 'SEND_PATIENT_REMINDER',
        DETERMINE_DIAGNOSIS = 'DETERMINE_DIAGNOSIS',
        DETERMINE_HTN_GOAL = 'DETERMINE_HTN_GOAL',
        RECOMMEND_LIFESTYLE = 'RECOMMEND_LIFESTYLE',
        RECOMMEND_DRUG_THERAPY = 'RECOMMEND_DRUG_THERAPY',
        RECOMMEND_TITRATE_MEDICATION = 'RECOMMEND_TITRATE_MEDICATION',
        RECOMMEND_DEESCALATION = 'RECOMMEND_DEESCALATION',
        RECOMMEND_MONITORING = 'RECOMMEND_MONITORING',
        REFER_TO_MD = 'REFER_TO_MD',
        REQUEST_BP_DATA_FROM_PATIENT = 'REQUEST_BP_DATA_FROM_PATIENT',
        REQUEST_LIPIDS_DATA_FROM_PATIENT = 'REQUEST_LIPIDS_DATA_FROM_PATIENT',
        REQUEST_LABS_FROM_PATIENT = 'REQUEST_LABS_FROM_PATIENT',
        REQUEST_CONDITION_HISTORY_FROM_PATIENT = 'REQUEST_CONDITION_HISTORY_FROM_PATIENT',
        REQUEST_BP_DATA_FROM_DATABASE = 'REQUEST_BP_DATA_FROM_DATABASE',
        REQUEST_LABS_DATA_FROM_DATABASE = 'REQUEST_LABS_DATA_FROM_DATABASE',
        REQUEST_CONDITION_HISTORY_FROM_DATABASE = 'REQUEST_CONDITION_HISTORY_FROM_DATABASE',
        NO_ACTION = 'NO_ACTION',
        OTHER = 'OTHER',
        ERROR = 'ERROR',
    }


}

