/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddressDto } from './AddressDto';

/**
 * An optional object, providing the actual result.
 */
export type PersonalAddressDto = {
    index?: number;
    addressType?: PersonalAddressDto.addressType;
    firstName?: string;
    lastName?: string;
    addressDto?: AddressDto;
};

export namespace PersonalAddressDto {

    export enum addressType {
        SHIPMENT = 'SHIPMENT',
        BILLING = 'BILLING',
    }


}

