/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessCollectionTaskResponse } from '../models/SuccessCollectionTaskResponse';
import type { SuccessTaskResponse } from '../models/SuccessTaskResponse';
import type { UpdateTaskRequest } from '../models/UpdateTaskRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaskControllerService {

    /**
     * Updates the given task.
     * @param taskId
     * @param requestBody
     * @returns SuccessTaskResponse OK
     * @throws ApiError
     */
    public static updateTaskById(
        taskId: string,
        requestBody: UpdateTaskRequest,
    ): CancelablePromise<SuccessTaskResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/tasks/{taskId}',
            path: {
                'taskId': taskId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Updates the given task.
     * @param program
     * @param group
     * @param slug
     * @param requestBody
     * @returns SuccessTaskResponse OK
     * @throws ApiError
     */
    public static updateTaskBySlug(
        program: string,
        group: string,
        slug: string,
        requestBody: UpdateTaskRequest,
    ): CancelablePromise<SuccessTaskResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/tasks/{program}/{group}/{slug}',
            path: {
                'program': program,
                'group': group,
                'slug': slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns all tasks for the current user in the given program.
     * @param programSlug
     * @returns SuccessCollectionTaskResponse OK
     * @throws ApiError
     */
    public static getProgramTasks(
        programSlug: string,
    ): CancelablePromise<SuccessCollectionTaskResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tasks/program/{programSlug}',
            path: {
                'programSlug': programSlug,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
