/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SendMessageRequest } from '../models/SendMessageRequest';
import type { SuccessGetMessagesResponse } from '../models/SuccessGetMessagesResponse';
import type { SuccessGetMessagingStatusResponse } from '../models/SuccessGetMessagingStatusResponse';
import type { SuccessSendMessageResponse } from '../models/SuccessSendMessageResponse';
import type { SuccessVoid } from '../models/SuccessVoid';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessageControllerService {

    /**
     * Marks a given message as read by the user.
     * @param messageId The message ID.
     * @returns SuccessVoid OK
     * @throws ApiError
     */
    public static markMessageAsRead(
        messageId: string,
    ): CancelablePromise<SuccessVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/messages/{messageId}/read',
            path: {
                'messageId': messageId,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Allows to retrieve user lifeline items with messages for given user.
     * @param messageOwnerId
     * @param ifNoneMatch Allows to fetch newest messages by ETag returned in previous response headers.
     * Each response (if any message returned) contains ETag header, which when passed to If-None-Match will cause to return only messages newer than these in previous response.
     * Value of that header must match UUID format, or be empty. Otherwise 400 - BAD_REQUEST is returned.
     * @returns SuccessGetMessagesResponse Messages have been fetched succesfully. ETag to fetch newer messages is returned as a header.
     * @throws ApiError
     */
    public static fetchMessages(
        messageOwnerId: string,
        ifNoneMatch?: string,
    ): CancelablePromise<SuccessGetMessagesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/messages/user/{messageOwnerId}',
            path: {
                'messageOwnerId': messageOwnerId,
            },
            headers: {
                'If-None-Match': ifNoneMatch,
            },
            errors: {
                304: `No new messages available.`,
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `User has no rights to perform this operation.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Stores posted message in given user's lifeline.
     * @param messageOwnerId The recipient of the message. Message is stored in owner's lifeline
     * @param requestBody
     * @returns SuccessSendMessageResponse The message was processed successfully.
     * @throws ApiError
     */
    public static postToOwnersLifeline(
        messageOwnerId: string,
        requestBody: SendMessageRequest,
    ): CancelablePromise<SuccessSendMessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/messages/user/{messageOwnerId}',
            path: {
                'messageOwnerId': messageOwnerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `User has no rights to perform this operation.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns the most recent messages for the requesting user.
     * @param size The maximum number of messages to be returned (default = 50)
     * @returns SuccessGetMessagesResponse OK
     * @throws ApiError
     */
    public static listRecentMessages(
        size: number = 50,
    ): CancelablePromise<SuccessGetMessagesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/messages',
            query: {
                'size': size,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns messages that are newer/older than the message with the provided id.
     * @param messageId The reference message ID. All returned messages will be newer or older than that one.
     * @param comparator The selector whether newer or older messages should be returned.
     * @param size The maximum number of messages to be returned (default = 50)
     * @returns SuccessGetMessagesResponse OK
     * @throws ApiError
     */
    public static listNearbyMessages(
        messageId: string,
        comparator: 'older' | 'newer',
        size: number = 50,
    ): CancelablePromise<SuccessGetMessagesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/messages/{messageId}/{comparator}',
            path: {
                'messageId': messageId,
                'comparator': comparator,
            },
            query: {
                'size': size,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns information about the requesting user's messaging status, including number of unread messages.
     * @returns SuccessGetMessagingStatusResponse OK
     * @throws ApiError
     */
    public static getMessagingStatus(): CancelablePromise<SuccessGetMessagingStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/messages/status',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
