/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Cancels a subscription and all associated recurring payments.
 */
export type AdminCancelSubscriptionRequest = {
    /**
     * The ID of the subscription.
     */
    subscriptionId: string;
    /**
     * The cancellation reason.
     */
    cancellationReason: AdminCancelSubscriptionRequest.cancellationReason;
    /**
     * Additional information about the cancellation reason.
     */
    cancellationText?: string;
};

export namespace AdminCancelSubscriptionRequest {

    /**
     * The cancellation reason.
     */
    export enum cancellationReason {
        ONETIME_PURCHASE = 'ONETIME_PURCHASE',
        MEDICAL_INELIGIBILITY = 'MEDICAL_INELIGIBILITY',
        PAYER_INELIGIBILITY = 'PAYER_INELIGIBILITY',
        PAYMENT_ISSUE = 'PAYMENT_ISSUE',
        OOP_COSTS = 'OOP_COSTS',
        DISENGAGEMENT = 'DISENGAGEMENT',
        MEMBER_REQUEST = 'MEMBER_REQUEST',
        OTHER = 'OTHER',
    }


}

