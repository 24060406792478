/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GuarantorItem } from './GuarantorItem';
import type { PayerAddressObject } from './PayerAddressObject';

/**
 * Information returned after a pharmacy insurance is created or updated.
 */
export type PharmacyInsuranceResponse = {
    /**
     * CardHolder ID of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    cardHolderId: string;
    /**
     * Group Number of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    rxGrp?: string;
    /**
     * Status of current Insurance/Coupon.
     */
    status: PharmacyInsuranceResponse.status;
    /**
     * The date when a insurance is effective from.
     */
    effectiveStartDate?: string;
    /**
     * The date when this insurance is effective until.
     */
    effectiveEndDate?: string;
    /**
     * The ID of uploaded file which is front of the card.
     */
    frontPhotoFileId?: string;
    /**
     * The ID of uploaded file which is back of the card.
     */
    backPhotoFileId?: string;
    /**
     * Bank Identification Number of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    rxBin: string;
    /**
     * Processor Control Number of a Pharmacy Insurance or a Manufacturer Coupon.
     */
    rxPcn?: string;
    /**
     * ID of user who stored insurance/coupon details.
     */
    createdBy: string;
    /**
     * ID of user who modified insurance/coupon details.
     */
    updatedBy: string;
    /**
     * The date/time when this object was created.
     */
    createdAt: string;
    /**
     * The date/time when this object was updated.
     */
    lastUpdated: string;
    /**
     * The name of the insurance plan.
     */
    planName?: string;
    /**
     * Flag - if an insurance is a primary.
     */
    isPrimary: boolean;
    /**
     * Relationship to a cardholder.
     */
    primaryPlanOwner: PharmacyInsuranceResponse.primaryPlanOwner;
    payer?: PayerAddressObject;
    /**
     * The id of partner.
     */
    partnerId?: string;
    guarantor?: GuarantorItem;
};

export namespace PharmacyInsuranceResponse {

    /**
     * Status of current Insurance/Coupon.
     */
    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }

    /**
     * Relationship to a cardholder.
     */
    export enum primaryPlanOwner {
        SELF = 'SELF',
        SPOUSE = 'SPOUSE',
        PARENT = 'PARENT',
        OTHER = 'OTHER',
        UNKNOWN = 'UNKNOWN',
    }


}

