/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CarePlanActionRequest } from './CarePlanActionRequest';

export type AddServiceRequestCarePlanActionRequest = (CarePlanActionRequest & {
    /**
     * The type of service that is requested.
     */
    serviceRequestType?: AddServiceRequestCarePlanActionRequest.serviceRequestType;
    /**
     * Valid until date.
     */
    validUntil?: string;
    /**
     * The provider of service that is requested.
     */
    performerType?: AddServiceRequestCarePlanActionRequest.performerType;
    /**
     * Additional comment.
     */
    comment?: string;
} & {
    /**
     * The type of service that is requested.
     */
    serviceRequestType: AddServiceRequestCarePlanActionRequest.serviceRequestType;
    /**
     * Valid until date.
     */
    validUntil: string;
    /**
     * The provider of service that is requested.
     */
    performerType: AddServiceRequestCarePlanActionRequest.performerType;
});

export namespace AddServiceRequestCarePlanActionRequest {

    /**
     * The type of service that is requested.
     */
    export enum serviceRequestType {
        BARIATRIC_SURGERY = 'BARIATRIC_SURGERY',
        REMOTE_PATIENT_MONITORING_ORDER = 'REMOTE_PATIENT_MONITORING_ORDER',
        STANDING_LAB_ORDER = 'STANDING_LAB_ORDER',
    }

    /**
     * The provider of service that is requested.
     */
    export enum performerType {
        TRANSCARENT = 'TRANSCARENT',
        NINE_AM_HEALTH = 'NINE_AM_HEALTH',
    }


}

