/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessUserCredentialsResponse } from '../models/SuccessUserCredentialsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RxDietControllerService {

    /**
     * Initiates an account creation
     * @returns SuccessUserCredentialsResponse OK
     * @throws ApiError
     */
    public static createUser(): CancelablePromise<SuccessUserCredentialsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/rxdiet/join',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Provides user credentials
     * @returns SuccessUserCredentialsResponse OK
     * @throws ApiError
     */
    public static getUserCredentials(): CancelablePromise<SuccessUserCredentialsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/rxdiet/credentials',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
