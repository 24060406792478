/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The response to a subscription payment.
 */
export type PaymentResponse = {
    /**
     * The ID of the subscription payment
     */
    id: string;
    /**
     * The currency of the subscription payment
     */
    currency: {
        currencyCode?: string;
        displayName?: string;
        symbol?: string;
        defaultFractionDigits?: number;
        numericCode?: number;
        numericCodeAsString?: string;
    };
    /**
     * The amount of the subscription payment
     */
    amount: number;
    /**
     * The payment creation date
     */
    createdDate: string;
    /**
     * The payment provider name
     */
    provider: PaymentResponse.provider;
    /**
     * The provider-specific id of the payment
     */
    providerPaymentId: string;
    /**
     *  The payment status
     */
    status: PaymentResponse.status;
};

export namespace PaymentResponse {

    /**
     * The payment provider name
     */
    export enum provider {
        STRIPE = 'STRIPE',
    }

    /**
     *  The payment status
     */
    export enum status {
        PENDING = 'PENDING',
        COMPLETE = 'COMPLETE',
        FAILED = 'FAILED',
        CANCELLED = 'CANCELLED',
    }


}

