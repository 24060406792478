/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A coupon that has been applied to a subscription.
 */
export type AppliedCouponResponse = {
    /**
     * The coupon code.
     */
    coupon: string;
    /**
     * Whether the coupon is a one-time or a recurring one.
     */
    duration: AppliedCouponResponse.duration;
    /**
     * The renewalInterval for which the coupon is applied to the subscription.
     */
    durationInMonths?: number;
    /**
     * The percentage of the discount, ie. the amount that is deducted from the price, normalized to 0..1
     */
    discountFactor?: number;
    /**
     * The absolute amount of money that the coupon provides in the currency expressed by amountOffCurrency
     */
    amountOff?: number;
    /**
     * If the coupon provides an absolute discount (amountOff), the currency in which that amount is expressed
     */
    amountOffCurrency?: string;
    /**
     * Durations of subscription to which the coupon is applicable
     */
    applicableSubscriptionRenewalIntervals: Array<string>;
};

export namespace AppliedCouponResponse {

    /**
     * Whether the coupon is a one-time or a recurring one.
     */
    export enum duration {
        REPEATING = 'REPEATING',
        ONCE = 'ONCE',
    }


}

