/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ShippableItem } from './ShippableItem';

/**
 * Information required to initiate a shipment.
 */
export type InitiateShippableItemsShipmentRequest = {
    /**
     * The user ID for whom a shipment should be initiated.
     */
    userId: string;
    /**
     * The shipment priority.
     */
    priority?: InitiateShippableItemsShipmentRequest.priority;
    /**
     * A collection of shippable items to be included in the shipment.
     */
    items: Array<ShippableItem>;
};

export namespace InitiateShippableItemsShipmentRequest {

    /**
     * The shipment priority.
     */
    export enum priority {
        HIGHEST = 'HIGHEST',
        HIGH = 'HIGH',
        REGULAR = 'REGULAR',
    }


}

