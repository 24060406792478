/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents a pharmacy.
 */
export type PharmacyDetailsResponse = {
    /**
     * The id of the pharmacy in the provider
     */
    providerPharmacyId: string;
    /**
     * Pharmacy Provider Type.
     */
    pharmacyInformationProviderType: PharmacyDetailsResponse.pharmacyInformationProviderType;
    /**
     * Pharmacy Name.
     */
    pharmacyName: string;
    /**
     * The first line of the pharmacy's address.
     */
    address1?: string;
    /**
     * The second line of the pharmacy's address.
     */
    address2?: string;
    /**
     * City where the pharmacy is located.
     */
    city?: string;
    /**
     * State where the pharmacy is located.
     */
    state?: string;
    /**
     * Zip code where the pharmacy is located.
     */
    zipCode?: string;
    /**
     * The primary phone number of the pharmacy.
     */
    primaryPhone?: string;
    /**
     * The type of the primary phone number of the pharmacy.
     */
    primaryPhoneType?: string;
    /**
     * The primary fax number of the pharmacy.
     */
    primaryFax?: string;
    /**
     * Additional phone number.
     */
    phoneAdditional1?: string;
    /**
     * Additional phone number type.
     */
    phoneAdditionalType1?: string;
    /**
     * Additional phone number.
     */
    phoneAdditional2?: string;
    /**
     * Additional phone number type.
     */
    phoneAdditionalType2?: string;
    /**
     * Additional phone number.
     */
    phoneAdditional3?: string;
    /**
     * Additional phone number type.
     */
    phoneAdditionalType3?: string;
    /**
     * Collection of pharmacy specialties.
     */
    pharmacySpecialties?: Array<'EPCS' | 'TWENTY_FOUR_HOUR_PHARMACY' | 'LONG_TERM_CARE_PHARMACY' | 'MAIL_ORDER' | 'RETAIL' | 'SPECIALTY_PHARMACY'>;
    /**
     * A list of services the pharmacy supports.
     */
    serviceLevels?: Array<string>;
    /**
     * Latitude of the pharmacy's location.
     */
    latitude?: number;
    /**
     * Longitude of the pharmacy's location.
     */
    longitude?: number;
    /**
     * indicates whether the pharmacy is available
     */
    status: PharmacyDetailsResponse.status;
};

export namespace PharmacyDetailsResponse {

    /**
     * Pharmacy Provider Type.
     */
    export enum pharmacyInformationProviderType {
        DOSESPOT = 'DOSESPOT',
    }

    /**
     * indicates whether the pharmacy is available
     */
    export enum status {
        AVAILABLE = 'AVAILABLE',
        UNAVAILABLE = 'UNAVAILABLE',
    }


}

