/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The response containing subscription state
 */
export type GetSubscriptionStateResponse = {
    /**
     * Subscription identifier
     */
    subscriptionId: string;
    /**
     * Subscription status
     */
    subscriptionStatus: GetSubscriptionStateResponse.subscriptionStatus;
};

export namespace GetSubscriptionStateResponse {

    /**
     * Subscription status
     */
    export enum subscriptionStatus {
        DRAFT = 'DRAFT',
        IN_REVIEW = 'IN_REVIEW',
        ACTIVE = 'ACTIVE',
        REJECTED = 'REJECTED',
        FINISHED = 'FINISHED',
        PAUSED = 'PAUSED',
    }


}

