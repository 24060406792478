/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Collection of lab orders
 */
export type GetLabOrdersResponseItem = {
    /**
     * Date when the order was registered
     */
    createdAt: string;
    /**
     * The lab provider where the test(s) were ordered
     */
    provider: GetLabOrdersResponseItem.provider;
    /**
     * Status of the lab order
     */
    status: GetLabOrdersResponseItem.status;
    /**
     * Optional tracking number, no specific format
     */
    trackingNumber?: string;
    /**
     * Optional tracking url
     */
    trackingUrl?: string;
    /**
     * Identifiers for lab tests in the order
     */
    orderedLabTests?: Array<'A1C' | 'ALANINE_TRANSAMINASE' | 'ASPARTATE_AMINOTRANSFERASE' | 'BMP' | 'CMP' | 'CREATININE' | 'C_PEPTIDE_SERUM' | 'DIABETES_PANEL' | 'FRUCTOSAMINE' | 'GAD65' | 'GLUCOSE' | 'HEPATIC_FUNCTION_PANEL' | 'LIPIDS' | 'POTASSIUM' | 'SODIUM' | 'THYROID_STIMULATING_HORMONE' | 'TOTAL_CHOLESTEROL' | 'TRIGLYCERIDES' | 'URINARY_ALBUMIN_TO_CREATININE_RATIO'>;
};

export namespace GetLabOrdersResponseItem {

    /**
     * The lab provider where the test(s) were ordered
     */
    export enum provider {
        TRUEPILL = 'TRUEPILL',
        BIO_REFERENCE_LAB = 'BIO_REFERENCE_LAB',
        PATIENT = 'PATIENT',
        ZENDESK = 'ZENDESK',
        GETLABS = 'GETLABS',
        QUEST = 'QUEST',
        LABCORP = 'LABCORP',
        EVERLYWELL = 'EVERLYWELL',
        TASSO = 'TASSO',
    }

    /**
     * Status of the lab order
     */
    export enum status {
        ORDERED = 'ORDERED',
        PENDING = 'PENDING',
        WAITING_FOR_LAB_REQUISITION_UPLOAD = 'WAITING_FOR_LAB_REQUISITION_UPLOAD',
        PROCESSING = 'PROCESSING',
        CANCELED = 'CANCELED',
        DECLINED = 'DECLINED',
        SHIPMENT_INITIATED = 'SHIPMENT_INITIATED',
        WAITING_FOR_PATIENT_TO_SCHEDULE_APPOINTMENT = 'WAITING_FOR_PATIENT_TO_SCHEDULE_APPOINTMENT',
        WAITING_FOR_PATIENT_TO_RESCHEDULE_APPOINTMENT = 'WAITING_FOR_PATIENT_TO_RESCHEDULE_APPOINTMENT',
        APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
        SHIPPING_TO_PATIENT = 'SHIPPING_TO_PATIENT',
        DELIVERED_TO_PATIENT = 'DELIVERED_TO_PATIENT',
        KIT_SENT_TO_LAB = 'KIT_SENT_TO_LAB',
        KIT_DELIVERED_TO_LAB = 'KIT_DELIVERED_TO_LAB',
        SPECIMENS_COLLECTED = 'SPECIMENS_COLLECTED',
        RESULTS_AVAILABLE = 'RESULTS_AVAILABLE',
        COMPLETED = 'COMPLETED',
    }


}

