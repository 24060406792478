/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessGetLabOrderProvidersResponse } from '../models/SuccessGetLabOrderProvidersResponse';
import type { SuccessGetLabOrdersResponse } from '../models/SuccessGetLabOrdersResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LabOrderControllerService {

    /**
     * Provides information about lab orders.
     * Provides data of all lab orders available for authenticated user.
     * @returns SuccessGetLabOrdersResponse Lab orders loaded successfully.
     * @throws ApiError
     */
    public static allLabOrders(): CancelablePromise<SuccessGetLabOrdersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/laborders',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Provides a list of available lab providers.
     * Provides a list of available lab providers for authenticated user.
     * @returns SuccessGetLabOrderProvidersResponse List of available lab providers loaded successfully.
     * @throws ApiError
     */
    public static availableLabOrderProviders(): CancelablePromise<SuccessGetLabOrderProvidersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/laborders/available-providers',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
