/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Contains info about supply as a list item
 */
export type SupplyItemResponse = {
    /**
     * Supply name
     */
    name: string;
    ndc: string;
    /**
     * Supply seller
     */
    seller?: SupplyItemResponse.seller;
};

export namespace SupplyItemResponse {

    /**
     * Supply seller
     */
    export enum seller {
        NINE_AM_HEALTH = 'NINE_AM_HEALTH',
    }


}

