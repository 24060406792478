/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data required to register a push notification token.
 */
export type RegisterTokenRequest = {
    /**
     * The platform on which the app is running.
     */
    appPlatform: RegisterTokenRequest.appPlatform;
    /**
     * The app ID of the associated application.
     */
    applicationId: string;
    /**
     * The platform for which the token is valid.
     */
    pushPlatform: RegisterTokenRequest.pushPlatform;
    /**
     * The actual token.
     */
    token: string;
    /**
     * An optional human-readable name for the device that this token is associated to.
     */
    name?: string;
};

export namespace RegisterTokenRequest {

    /**
     * The platform on which the app is running.
     */
    export enum appPlatform {
        IOS = 'IOS',
        ANDROID = 'ANDROID',
    }

    /**
     * The platform for which the token is valid.
     */
    export enum pushPlatform {
        APNS = 'APNS',
        FCM = 'FCM',
    }


}

