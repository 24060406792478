/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PrescriptionProviderSpecificsAdminResponse } from './PrescriptionProviderSpecificsAdminResponse';
import type { ProviderDto } from './ProviderDto';

/**
 * The Information about prescription.
 */
export type PrescriptionResponse = {
    /**
     * The Prescription id.
     */
    id: string;
    /**
     * The Medication name.
     */
    medicationName: string;
    medicationNdc: string;
    /**
     * The Prescription days of supply.
     */
    daysOfSupply?: number;
    /**
     * The Prescription quantity.
     */
    quantity: number;
    /**
     * The Prescription sig.
     */
    sig: string;
    prescriber: ProviderDto;
    /**
     * The Prescribed date.
     */
    prescribedAt: string;
    /**
     * The Expiration date.
     */
    expiredAt: string;
    /**
     * The Date when the prescription was created in db
     */
    createdAt: string;
    /**
     * The Prescription total refills.
     */
    totalRefills: number;
    /**
     * The Prescription remaining refills.
     */
    remainingRefills?: number;
    /**
     * The Provides-specific information about the prescription.
     */
    prescriptionProviderSpecificsAdminResponse?: Record<string, PrescriptionProviderSpecificsAdminResponse>;
    /**
     * The Pharmacy id.
     */
    pharmacyId?: string;
    /**
     * The proxy user on behalf of provider.
     */
    onBehalfOfUserId?: string;
    /**
     * The type of prescription
     */
    prescriptionType: PrescriptionResponse.prescriptionType;
    diagnosisCode?: string;
    /**
     * The diagnosis name.
     */
    diagnosisDisplayName?: string;
    /**
     * The form in which the medication is delivered.
     */
    doseForm: PrescriptionResponse.doseForm;
    substitutionAllowed?: boolean;
};

export namespace PrescriptionResponse {

    /**
     * The type of prescription
     */
    export enum prescriptionType {
        SUPPLY = 'SUPPLY',
        MEDICATION = 'MEDICATION',
    }

    /**
     * The form in which the medication is delivered.
     */
    export enum doseForm {
        CAPSULE = 'CAPSULE',
        CAPLET = 'CAPLET',
        FILM = 'FILM',
        GUM = 'GUM',
        IMPLANT = 'IMPLANT',
        INSERT = 'INSERT',
        LOZENGE = 'LOZENGE',
        PATCH = 'PATCH',
        RING = 'RING',
        SPONGE = 'SPONGE',
        STICK = 'STICK',
        STRIP = 'STRIP',
        SUPPOSITORY = 'SUPPOSITORY',
        TABLET = 'TABLET',
        APPLICATOR = 'APPLICATOR',
        BLISTER = 'BLISTER',
        KIT = 'KIT',
        PACKET = 'PACKET',
        PAD = 'PAD',
        SWAB = 'SWAB',
        UNSPECIFIED = 'UNSPECIFIED',
        WAFER = 'WAFER',
        EACH = 'EACH',
        GRAM = 'GRAM',
        MILLILITER = 'MILLILITER',
        UNIT = 'UNIT',
        LANCET = 'LANCET',
        PEN_NEEDLE = 'PEN_NEEDLE',
        TROCHE = 'TROCHE',
    }


}

