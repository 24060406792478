/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessCollectionFeatureResponse } from '../models/SuccessCollectionFeatureResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeaturesControllerService {

    /**
     * Returns the current feature configuration.
     * Returns a list of all known features including their current status (active/inactive).
     * @returns SuccessCollectionFeatureResponse OK
     * @throws ApiError
     */
    public static listFeatures(): CancelablePromise<SuccessCollectionFeatureResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/features',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
