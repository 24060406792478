/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Address
 */
export type ProviderAddress = {
    /**
     * Street with number
     */
    street: string;
    /**
     * Apartment or suite
     */
    aptSuite?: string;
    /**
     * City name
     */
    city: string;
    /**
     * State abbreviation
     */
    state: ProviderAddress.state;
    /**
     * US zip code
     */
    zip: string;
};

export namespace ProviderAddress {

    /**
     * State abbreviation
     */
    export enum state {
        AA = 'AA',
        AE = 'AE',
        AL = 'AL',
        AK = 'AK',
        AP = 'AP',
        AS = 'AS',
        AZ = 'AZ',
        AR = 'AR',
        CA = 'CA',
        CO = 'CO',
        CT = 'CT',
        DE = 'DE',
        DC = 'DC',
        FM = 'FM',
        FL = 'FL',
        GA = 'GA',
        GU = 'GU',
        HI = 'HI',
        ID = 'ID',
        IL = 'IL',
        IN = 'IN',
        IA = 'IA',
        KS = 'KS',
        KY = 'KY',
        LA = 'LA',
        ME = 'ME',
        MD = 'MD',
        MH = 'MH',
        MA = 'MA',
        MI = 'MI',
        MN = 'MN',
        MS = 'MS',
        MO = 'MO',
        MT = 'MT',
        MP = 'MP',
        NE = 'NE',
        NV = 'NV',
        NH = 'NH',
        NJ = 'NJ',
        NM = 'NM',
        NY = 'NY',
        NC = 'NC',
        ND = 'ND',
        OH = 'OH',
        OK = 'OK',
        OR = 'OR',
        PW = 'PW',
        PA = 'PA',
        PR = 'PR',
        RI = 'RI',
        SC = 'SC',
        SD = 'SD',
        TN = 'TN',
        TX = 'TX',
        UT = 'UT',
        VT = 'VT',
        VI = 'VI',
        VA = 'VA',
        WA = 'WA',
        WV = 'WV',
        WI = 'WI',
        WY = 'WY',
    }


}

