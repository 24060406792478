/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SetDefaultPaymentMethodRequest } from '../models/SetDefaultPaymentMethodRequest';
import type { SuccessGetPaymentMethodsResponse } from '../models/SuccessGetPaymentMethodsResponse';
import type { SuccessGetUserPortalUrlResponse } from '../models/SuccessGetUserPortalUrlResponse';
import type { SuccessInitiatePaymentMethodCollectionResponse } from '../models/SuccessInitiatePaymentMethodCollectionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentControllerService {

    /**
     * Returns the user's payment methods.
     * Returns a list of the user's payment methods.
     * @returns SuccessGetPaymentMethodsResponse A list of payment methods.
     * @throws ApiError
     */
    public static getPaymentMethods(): CancelablePromise<SuccessGetPaymentMethodsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/payments/methods',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Initiates the collection of a new payment method.
     * This initiates the collection of a payment method. The service will return a set of parameters that need to be used for the actual collection of the payment method.
     * @returns SuccessInitiatePaymentMethodCollectionResponse The parameters to be used for the actual collection of the payment method.
     * @throws ApiError
     */
    public static initiatePaymentMethodCollection(): CancelablePromise<SuccessInitiatePaymentMethodCollectionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/payments/methods',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Sets the default payment method for a given user.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static setDefaultPaymentMethod(
        requestBody: SetDefaultPaymentMethodRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/payments/methods/default',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The specified payment method does not exist.`,
            },
        });
    }

    /**
     * Returns a URL to the payment portal URL for the user.
     * This returns a URL that can be provided to the user to manage their payment options. This URL is only valid for a short time, therefore it should be fetched immediately before it is being actually opened.
     * @param returnUrl
     * @returns SuccessGetUserPortalUrlResponse Information about the portal URL.
     * @throws ApiError
     */
    public static getUserPortalUrl(
        returnUrl: string,
    ): CancelablePromise<SuccessGetUserPortalUrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/payments/portal',
            query: {
                'returnUrl': returnUrl,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
