/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StoreUserPcpRequest } from '../models/StoreUserPcpRequest';
import type { SuccessCollectionActiveProviderResponse } from '../models/SuccessCollectionActiveProviderResponse';
import type { SuccessPageProviderResponse } from '../models/SuccessPageProviderResponse';
import type { SuccessProviderResponse } from '../models/SuccessProviderResponse';
import type { SupplyProviderInformationQuery } from '../models/SupplyProviderInformationQuery';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProviderControllerService {

    /**
     * Lookup a user's PCP
     * @returns SuccessProviderResponse OK
     * @throws ApiError
     */
    public static lookupPcpForUserV2(): CancelablePromise<SuccessProviderResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/providers/pcp',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Store primary care provider information for a user.
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static storePcpForUserV2(
        requestBody: StoreUserPcpRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/providers/pcp',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Search for physicians
     * @param query
     * @param page
     * @param size
     * @param sort
     * @param sortDirection
     * @param lastName
     * @returns SuccessPageProviderResponse OK
     * @throws ApiError
     */
    public static searchPrimaryCarePhysiciansV2(
        query: SupplyProviderInformationQuery,
        page?: string,
        size?: string,
        sort?: string,
        sortDirection?: string,
        lastName?: string,
    ): CancelablePromise<SuccessPageProviderResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/providers',
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'sortDirection': sortDirection,
                'lastName': lastName,
                'query': query,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns a list of active 9amHealth physicians.
     * @returns SuccessCollectionActiveProviderResponse OK
     * @throws ApiError
     */
    public static findActivePhysiciansV2(): CancelablePromise<SuccessCollectionActiveProviderResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/providers/active',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
