/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The Prescriber info.
 */
export type ProviderDto = {
    id?: string;
    userId?: string;
    lastUpdated?: string;
    npi?: string;
    npiLastUpdated?: string;
    firstName?: string;
    lastName?: string;
    credential?: ProviderDto.credential;
    displayName?: string;
    displayNameWithRole?: string;
};

export namespace ProviderDto {

    export enum credential {
        UNKNOWN = 'UNKNOWN',
        DOCTOR_OF_MEDICINE = 'DOCTOR_OF_MEDICINE',
        DOCTOR_OF_OSTEOPATHIC_MEDICINE = 'DOCTOR_OF_OSTEOPATHIC_MEDICINE',
        NURSE_PRACTITIONER = 'NURSE_PRACTITIONER',
        PHYSICIAN_ASSISTANT = 'PHYSICIAN_ASSISTANT',
    }


}

