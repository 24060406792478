/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateLabOrderRequest = {
    /**
     * The ID of the lab order
     */
    labOrderId: string;
    /**
     * The new lab order provider
     */
    newLabOrderProviderType: UpdateLabOrderRequest.newLabOrderProviderType;
    /**
     * The reference id of the lab order for the new lab order provider
     */
    newProviderOrderReferenceId?: string;
    /**
     * Set of lab tests, associated with reasons, that overwrite the ones currently in place
     */
    newTestsWithReasons?: Record<string, Array<string>>;
    /**
     * The new status of the lab order
     */
    newStatus?: UpdateLabOrderRequest.newStatus;
};

export namespace UpdateLabOrderRequest {

    /**
     * The new lab order provider
     */
    export enum newLabOrderProviderType {
        TRUEPILL = 'TRUEPILL',
        BIO_REFERENCE_LAB = 'BIO_REFERENCE_LAB',
        PATIENT = 'PATIENT',
        ZENDESK = 'ZENDESK',
        GETLABS = 'GETLABS',
        QUEST = 'QUEST',
        LABCORP = 'LABCORP',
        EVERLYWELL = 'EVERLYWELL',
        TASSO = 'TASSO',
    }

    /**
     * The new status of the lab order
     */
    export enum newStatus {
        ORDERED = 'ORDERED',
        PENDING = 'PENDING',
        WAITING_FOR_LAB_REQUISITION_UPLOAD = 'WAITING_FOR_LAB_REQUISITION_UPLOAD',
        PROCESSING = 'PROCESSING',
        CANCELED = 'CANCELED',
        DECLINED = 'DECLINED',
        SHIPMENT_INITIATED = 'SHIPMENT_INITIATED',
        WAITING_FOR_PATIENT_TO_SCHEDULE_APPOINTMENT = 'WAITING_FOR_PATIENT_TO_SCHEDULE_APPOINTMENT',
        WAITING_FOR_PATIENT_TO_RESCHEDULE_APPOINTMENT = 'WAITING_FOR_PATIENT_TO_RESCHEDULE_APPOINTMENT',
        APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
        SHIPPING_TO_PATIENT = 'SHIPPING_TO_PATIENT',
        DELIVERED_TO_PATIENT = 'DELIVERED_TO_PATIENT',
        KIT_SENT_TO_LAB = 'KIT_SENT_TO_LAB',
        KIT_DELIVERED_TO_LAB = 'KIT_DELIVERED_TO_LAB',
        SPECIMENS_COLLECTED = 'SPECIMENS_COLLECTED',
        RESULTS_AVAILABLE = 'RESULTS_AVAILABLE',
        COMPLETED = 'COMPLETED',
    }


}

