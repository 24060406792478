/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the questionnaire reference with id and provider type
 */
export type QuestionnaireRef = {
    /**
     * Questionnaire provider type
     */
    type: QuestionnaireRef.type;
    /**
     * Questionnaire id
     */
    id: string;
};

export namespace QuestionnaireRef {

    /**
     * Questionnaire provider type
     */
    export enum type {
        TYPEFORM = 'TYPEFORM',
        NINEAM_HEALTH = 'NINEAM_HEALTH',
    }


}

