/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Request to delete a device.
 */
export type DeleteDeviceRequest = {
    /**
     * Reason, why the device is deleted
     */
    expirationReason: DeleteDeviceRequest.expirationReason;
};

export namespace DeleteDeviceRequest {

    /**
     * Reason, why the device is deleted
     */
    export enum expirationReason {
        ENTERED_IN_ERROR = 'ENTERED_IN_ERROR',
        NEWER_PRESCRIPTION_ADDED = 'NEWER_PRESCRIPTION_ADDED',
    }


}

