/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessZipCodeLookupResponse } from '../models/SuccessZipCodeLookupResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ZipCodeLookupControllerService {

    /**
     * Returns the state for a provided US ZipCode.
     * @param zipCode Zip Code to look up
     * @returns SuccessZipCodeLookupResponse State retrieved successfully.
     * @throws ApiError
     */
    public static getStateByZipCode(
        zipCode: string,
    ): CancelablePromise<SuccessZipCodeLookupResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/zip-code-lookup/{zipCode}',
            path: {
                'zipCode': zipCode,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `State not found for given Zip Code.`,
            },
        });
    }

}
