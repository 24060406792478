/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The response to a subscription purchase item.
 */
export type PurchaseItemResponse = {
    /**
     * The ID of the subscription item
     */
    id: string;
    sku: string;
    /**
     * A human-readable display name for this item
     */
    displayName: string;
    /**
     * Additional data related purchase item
     */
    metadata: Record<string, any>;
    /**
     * The name/id of item provider
     */
    itemProvider: PurchaseItemResponse.itemProvider;
    /**
     * The type of item in given provider
     */
    externalItemType: PurchaseItemResponse.externalItemType;
    /**
     * The price of subscription item
     */
    price: number;
    /**
     * The date/time when the item was added to the subscription.
     */
    addedAt: string;
    /**
     * The timestamp of the expiration.
     */
    expiredAt?: string;
    /**
     * The reason of the expiration.
     */
    expirationReason?: PurchaseItemResponse.expirationReason;
    /**
     * The price of subscription item
     */
    totalPrice: number;
    /**
     * Represents quantity of specific item
     */
    quantity?: number;
    /**
     * Represents quantity of specific item price
     */
    priceQuantity?: number;
    /**
     * The timestamp of planned expiration
     */
    expirationScheduledAt?: string;
};

export namespace PurchaseItemResponse {

    /**
     * The name/id of item provider
     */
    export enum itemProvider {
        OTC = 'OTC',
        TREATMENT_PLAN = 'TREATMENT_PLAN',
        PRESCRIPTION = 'PRESCRIPTION',
    }

    /**
     * The type of item in given provider
     */
    export enum externalItemType {
        SUBSCRIPTION_PLAN_SUGGESTION = 'SubscriptionPlanSuggestion',
        LAB_TEST_SUGGESTION = 'LabTestSuggestion',
        MEDICATION_SUGGESTION = 'MedicationSuggestion',
        PRELIMINARY_MEDICATION_SUGGESTION = 'PreliminaryMedicationSuggestion',
        PRESCRIBED_MEDICATION = 'PrescribedMedication',
        DOCUMENTED_MEDICATION = 'DocumentedMedication',
        PRESCRIBED_SUPPLY = 'PrescribedSupply',
        OVER_THE_COUNTER_GLUCOMETER = 'OverTheCounterGlucometer',
        OVER_THE_COUNTER_LAB_TEST = 'OverTheCounterLabTest',
        OVER_THE_COUNTER_MICROLET_COLOR_LANCET_ITEM = 'OverTheCounterMicroletColorLancetItem',
        OVER_THE_COUNTER_STRIP_ITEM = 'OverTheCounterStripItem',
        SUBSCRIBED_DEVICE = 'SubscribedDevice',
    }

    /**
     * The reason of the expiration.
     */
    export enum expirationReason {
        ALTERNATIVE_MEDICATION_SUPPLIER = 'ALTERNATIVE_MEDICATION_SUPPLIER',
        MEDICATION_ENTERED_INCORRECT_BY_PATIENT = 'MEDICATION_ENTERED_INCORRECT_BY_PATIENT',
        MEDICATION_INTAKE_DISCONTINUED = 'MEDICATION_INTAKE_DISCONTINUED',
        MEDICATION_INTAKE_DISCONTINUED_BY_OUTSIDE_PROVIDER = 'MEDICATION_INTAKE_DISCONTINUED_BY_OUTSIDE_PROVIDER',
        MEDICATION_NOT_PRESCRIBED = 'MEDICATION_NOT_PRESCRIBED',
        MEDICATION_NOT_WANTED = 'MEDICATION_NOT_WANTED',
        MEDICATION_SUPPLY_STILL_AVAILABLE = 'MEDICATION_SUPPLY_STILL_AVAILABLE',
        NEW_DOSAGE_PRESCRIBED = 'NEW_DOSAGE_PRESCRIBED',
        NEW_DOSAGE_PRESCRIBED_BY_OUTSIDE_PROVIDER = 'NEW_DOSAGE_PRESCRIBED_BY_OUTSIDE_PROVIDER',
        NEW_DOSAGE_RECOMMENDED_TO_OUTSIDE_PROVIDER = 'NEW_DOSAGE_RECOMMENDED_TO_OUTSIDE_PROVIDER',
        PRELIMINARY_MEDICATION_NOT_NEEDED_ANYMORE = 'PRELIMINARY_MEDICATION_NOT_NEEDED_ANYMORE',
        PRELIMINARY_MEDICATION_REPLACED = 'PRELIMINARY_MEDICATION_REPLACED',
        SIDE_EFFECTS = 'SIDE_EFFECTS',
        AUTOMATIC_LAB_ITEM_UPDATE = 'AUTOMATIC_LAB_ITEM_UPDATE',
        LAB_FROM_DIFFERENT_PROVIDER = 'LAB_FROM_DIFFERENT_PROVIDER',
        LAB_TESTS_NOT_SELECTED = 'LAB_TESTS_NOT_SELECTED',
        ADMIN = 'ADMIN',
        EXPIRATION_DATE_REACHED = 'EXPIRATION_DATE_REACHED',
        METADATA_CHANGED = 'METADATA_CHANGED',
        NEW_TREATMENT_PLAN_EVIDENCE_AVAILABLE = 'NEW_TREATMENT_PLAN_EVIDENCE_AVAILABLE',
        ONE_TIME_ITEM_FINISHED = 'ONE_TIME_ITEM_FINISHED',
        MOVED_TO_9AM = 'MOVED_TO_9AM',
        UNKNOWN = 'UNKNOWN',
    }


}

