/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DeviceModelResponse } from './DeviceModelResponse';

/**
 * An optional object, providing the actual result.
 */
export type DeviceOptionResponse = {
    /**
     * The type of device
     */
    type: DeviceOptionResponse.type;
    /**
     * The display name of the device type
     */
    typeDisplayName: string;
    /**
     * A list of possible models for the device type
     */
    models: Array<DeviceModelResponse>;
};

export namespace DeviceOptionResponse {

    /**
     * The type of device
     */
    export enum type {
        BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
        GLUCOMETER = 'GLUCOMETER',
        CGM = 'CGM',
        WEIGHT_SCALE = 'WEIGHT_SCALE',
        TAPE_MEASURE = 'TAPE_MEASURE',
        MOBILE_PHONE = 'MOBILE_PHONE',
    }


}

