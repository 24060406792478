/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Recommended care plan actions.
 */
export type CarePlanActionRequest = {
    /**
     * Id of the care plan action
     */
    id?: string;
    /**
     * Foreseen start date of the care plan action
     */
    startDate?: string;
    /**
     * Textual description of the care plan action
     */
    description?: string;
    /**
     * Type of the care plan action
     */
    type: CarePlanActionRequest.type;
};

export namespace CarePlanActionRequest {

    /**
     * Type of the care plan action
     */
    export enum type {
        ADD_STANDING_LAB_ORDER = 'AddStandingLabOrder',
        DO_LAB_TEST = 'DoLabTest',
        TAKE_MEDICATION = 'TakeMedication',
        RENEW_STANDING_LAB_ORDER = 'RenewStandingLabOrder',
        ADD_MEDICATION = 'AddMedication',
        ADJUST_MEDICATION = 'AdjustMedication',
        STOP_MEDICATION = 'StopMedication',
        RENEW_MEDICATION = 'RenewMedication',
        CONTINUE_MEDICATION = 'ContinueMedication',
        DOCUMENT_MEDICATION = 'DocumentMedication',
        ADD_SUPPLY = 'AddSupply',
        ADJUST_SUPPLY = 'AdjustSupply',
        CONTINUE_SUPPLY = 'ContinueSupply',
        STOP_SUPPLY = 'StopSupply',
        RENEW_SUPPLY = 'RenewSupply',
        ANSWER_QUESTION = 'AnswerQuestion',
        TAKE_CONSULTATION_NOTES = 'TakeConsultationNotes',
        ADD_SERVICE_REQUEST = 'AddServiceRequest',
        CONTINUE_SERVICE_REQUEST = 'ContinueServiceRequest',
        RENEW_SERVICE_REQUEST = 'RenewServiceRequest',
        CONTINUE_DIAGNOSIS = 'ContinueDiagnosis',
        ADD_DIAGNOSIS = 'AddDiagnosis',
        STOP_DIAGNOSIS = 'StopDiagnosis',
    }


}

