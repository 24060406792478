/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonNode } from '../models/JsonNode';
import type { SuccessListUserLifelineItemResponseObject } from '../models/SuccessListUserLifelineItemResponseObject';
import type { SuccessUserLifelineItemResponseObject } from '../models/SuccessUserLifelineItemResponseObject';
import type { SuccessUUID } from '../models/SuccessUUID';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserLifelineItemControllerService {

    /**
     * Adds new item to user's lifeline.
     * Creates lifeline item of given type, with given payload in user's lifeline
     * @param itemType The type of the item to be created.
     * @param requestBody The JSON payload of the item
     * @returns SuccessUUID Lifeline item was created and saved succesfully.
     * @throws ApiError
     */
    public static addLifelineItem(
        itemType: string,
        requestBody: JsonNode,
    ): CancelablePromise<SuccessUUID> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/lifeline/{itemType}',
            path: {
                'itemType': itemType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Provided payload doesn't match the structure or requirements of given item type.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                403: `Authenticated user does not have access lifeline items of given type.`,
                404: `Operation for lifeline item of given type cannot be handled.`,
            },
        });
    }

    /**
     * Fetches lifeline items.
     * Returns all lifeline items for given query parameters.
     * @param itemType
     * @returns SuccessListUserLifelineItemResponseObject Lifeline items returned succesfully.
     * @throws ApiError
     */
    public static getLifelineItems(
        itemType: Array<string>,
    ): CancelablePromise<SuccessListUserLifelineItemResponseObject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/lifeline',
            query: {
                'itemType': itemType,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                403: `Authenticated user does not have access to lifeline items.`,
                404: `Operation for lifeline item of given type cannot be handled.`,
            },
        });
    }

    /**
     * Returns a user's lifeline item by id.
     * @param lifelineItemId
     * @returns SuccessUserLifelineItemResponseObject OK
     * @throws ApiError
     */
    public static getLifelineItem(
        lifelineItemId: string,
    ): CancelablePromise<SuccessUserLifelineItemResponseObject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/lifeline/{lifelineItemId}',
            path: {
                'lifelineItemId': lifelineItemId,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
