/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MeasurementUnitCodeResponse } from './MeasurementUnitCodeResponse';
import type { ObservationTemporalPeriodResponse } from './ObservationTemporalPeriodResponse';

/**
 * Structure to retrieve an observation code
 */
export type ObservationCodeResponse = {
    /**
     * LOINC code of the observation
     */
    code: string;
    /**
     * Short textual representation of the observation for internal purpose
     */
    internalName: string;
    /**
     * Official loinc name
     */
    loincName: string;
    /**
     * Unit in which the observation is measured
     */
    measurementUnit: Array<MeasurementUnitCodeResponse>;
    /**
     * Observation category of the observation
     */
    observationCategory: ObservationCodeResponse.observationCategory;
    /**
     * Temporal period when this observation was measured
     */
    temporalPeriods: Array<ObservationTemporalPeriodResponse>;
    component?: boolean;
};

export namespace ObservationCodeResponse {

    /**
     * Observation category of the observation
     */
    export enum observationCategory {
        SOCIALHISTORY = 'SOCIALHISTORY',
        VITALSIGNS = 'VITALSIGNS',
        IMAGING = 'IMAGING',
        LABORATORY = 'LABORATORY',
        PROCEDURE = 'PROCEDURE',
        SURVEY = 'SURVEY',
        EXAM = 'EXAM',
        THERAPY = 'THERAPY',
        ACTIVITY = 'ACTIVITY',
        NULL = 'NULL',
    }


}

