/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CarePlanActionRequest } from './CarePlanActionRequest';

export type AddDiagnosisCarePlanActionRequest = (CarePlanActionRequest & {
    /**
     * diagnosis code
     */
    diagnosisCode?: string;
    /**
     * diagnosis name
     */
    name?: string;
    /**
     * diagnosis start month
     */
    month?: AddDiagnosisCarePlanActionRequest.month;
    /**
     * diagnosis start year
     */
    year?: {
        value?: number;
        leap?: boolean;
    };
} & {
    /**
     * diagnosis name
     */
    name: string;
});

export namespace AddDiagnosisCarePlanActionRequest {

    /**
     * diagnosis start month
     */
    export enum month {
        JANUARY = 'JANUARY',
        FEBRUARY = 'FEBRUARY',
        MARCH = 'MARCH',
        APRIL = 'APRIL',
        MAY = 'MAY',
        JUNE = 'JUNE',
        JULY = 'JULY',
        AUGUST = 'AUGUST',
        SEPTEMBER = 'SEPTEMBER',
        OCTOBER = 'OCTOBER',
        NOVEMBER = 'NOVEMBER',
        DECEMBER = 'DECEMBER',
    }


}

