/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessGetOpenOnboardingCallsResponse } from '../models/SuccessGetOpenOnboardingCallsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CareControllerService {

    /**
     * Report patient treatment plan changes
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static reportPatientIssue(
        requestBody: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/care/detected-issues/patient-preferences',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Triggers the callback of an onboarding call.
     * @returns string OK
     * @throws ApiError
     */
    public static triggerOnboardingCallback(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/care/calls/onboarding',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Cancels a requested onboarding callback.
     * @returns string OK
     * @throws ApiError
     */
    public static cancelOnboardingCallback(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/care/calls/onboarding',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns the number of open tickets for onboarding calls.
     * @returns SuccessGetOpenOnboardingCallsResponse OK
     * @throws ApiError
     */
    public static getOpenOnboardingCalls(): CancelablePromise<SuccessGetOpenOnboardingCallsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/care/calls/onboarding/open',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
