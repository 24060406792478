/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information about an active physician.
 */
export type ActivePhysicianResponse = {
    /**
     * The provider's ID.
     */
    id: string;
    /**
     * The provider's first name.
     */
    firstName?: string;
    /**
     * The provider's last name.
     */
    lastName?: string;
    /**
     * The provider's credential.
     */
    credential: ActivePhysicianResponse.credential;
    /**
     * The provider's display name.
     */
    displayName?: string;
    /**
     * The provider's display name, including their role.
     */
    displayNameWithRole?: string;
};

export namespace ActivePhysicianResponse {

    /**
     * The provider's credential.
     */
    export enum credential {
        UNKNOWN = 'UNKNOWN',
        DOCTOR_OF_MEDICINE = 'DOCTOR_OF_MEDICINE',
        DOCTOR_OF_OSTEOPATHIC_MEDICINE = 'DOCTOR_OF_OSTEOPATHIC_MEDICINE',
        NURSE_PRACTITIONER = 'NURSE_PRACTITIONER',
        PHYSICIAN_ASSISTANT = 'PHYSICIAN_ASSISTANT',
    }


}

