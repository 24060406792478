/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AnswerItemDto } from './AnswerItemDto';
import type { IneligibilityReason } from './IneligibilityReason';
import type { QuestionnaireRef } from './QuestionnaireRef';
import type { TreatmentPlanPurchaseItem } from './TreatmentPlanPurchaseItem';

/**
 * Represents the treatment plan suggestion. Contains all the data related to the suggested treatment plan or suggested next step to reach treatment plan suggestion . Can also provide information about ineligibility
 */
export type GetSuggestedTreatmentPlanResponse = {
    /**
     * Eligibility user status one of : ELIGIBLE, INELIGIBLE, DATA_NEEDED
     */
    eligibility: GetSuggestedTreatmentPlanResponse.eligibility;
    /**
     * A list of reasons in case the user is ineligible.
     */
    ineligibilityReasons: Array<IneligibilityReason>;
    dataNeededReasons?: Array<'STATE' | 'NAME' | 'DATE_OF_BIRTH' | 'ANONYMOUS' | 'A1C_MISSING' | 'A1C_ORDERED_NO_RESULT' | 'SEX' | 'PREGNANT_OR_BREASTFEEDING' | 'MEDICAL_CONDITIONS' | 'MEDICATIONS' | 'MEDICAL_SYMPTOMS' | 'BLOOD_PRESSURE_MISSING' | 'MEDICARE' | 'CARE_TEAM_CALL' | 'ETHNICITIES' | 'BMI'>;
    /**
     * Purchase items collection
     */
    purchaseItems?: Array<TreatmentPlanPurchaseItem>;
    questionnaireRef?: QuestionnaireRef;
    alternativeStep?: QuestionnaireRef;
    /**
     * Answers from last fulfilled questionnaire
     */
    answers?: Array<AnswerItemDto>;
};

export namespace GetSuggestedTreatmentPlanResponse {

    /**
     * Eligibility user status one of : ELIGIBLE, INELIGIBLE, DATA_NEEDED
     */
    export enum eligibility {
        ELIGIBLE = 'ELIGIBLE',
        INELIGIBLE = 'INELIGIBLE',
        DATA_NEEDED = 'DATA_NEEDED',
    }


}

