/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Additional data for deleting a lifeline item.
 */
export type AdminDeleteLifelineItemRequest = {
    /**
     * The reason for the deletion of the item.
     */
    reason: AdminDeleteLifelineItemRequest.reason;
};

export namespace AdminDeleteLifelineItemRequest {

    /**
     * The reason for the deletion of the item.
     */
    export enum reason {
        CORRECTED_ERROR = 'CORRECTED_ERROR',
    }


}

