/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessCreateFileResponse } from '../models/SuccessCreateFileResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserAvatarControllerService {

    /**
     * Uploads avatar for authenticated user.
     * Allows to assign avatar to currently logged user
     * @param requestBody The avatar file content.
     * @returns SuccessCreateFileResponse The avatar was uploaded successfully; the file ID is returned in the response body.
     * @throws ApiError
     */
    public static createAvatar(
        requestBody: any,
    ): CancelablePromise<SuccessCreateFileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/avatar',
            body: requestBody,
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Downloads user avatar.
     * Allows the client to download users avatar, identified by users id (UUID).
     * @param userId User id of owner of the avatar.
     * @returns any The avatar content.
     * @throws ApiError
     */
    public static get(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/{userId}/avatar',
            path: {
                'userId': userId,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `No avatar for given user exists.`,
            },
        });
    }

}
