/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PopulateUserRequest = {
    /**
     * Handover token. If omitted, no data will be populated but the patient will be marked as "lilly populated".
     */
    token?: string;
    /**
     * The handover type to identify the funnel the user is going through.
     */
    type?: PopulateUserRequest.type;
};

export namespace PopulateUserRequest {

    /**
     * The handover type to identify the funnel the user is going through.
     */
    export enum type {
        DIABETES = 'DIABETES',
        OBESITY = 'OBESITY',
    }


}

