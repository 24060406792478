/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RpmObservation } from './RpmObservation';

/**
 * Provides the status of the RPM for a month.
 */
export type AdminRpmStatusResponse = {
    /**
     * The year and month for which the RPM status is returned.
     */
    yearMonth: {
        year?: number;
        month?: AdminRpmStatusResponse.month;
        monthValue?: number;
        leapYear?: boolean;
    };
    /**
     * The current number of days with observations that count towards the RPM status.
     */
    count: number;
    /**
     * The target number of days with observations that count towards the RPM status.
     */
    target: number;
    /**
     * A list of days along with the observations that count towards the RPM status.
     */
    rpmObservations: Array<RpmObservation>;
};

export namespace AdminRpmStatusResponse {

    export enum month {
        JANUARY = 'JANUARY',
        FEBRUARY = 'FEBRUARY',
        MARCH = 'MARCH',
        APRIL = 'APRIL',
        MAY = 'MAY',
        JUNE = 'JUNE',
        JULY = 'JULY',
        AUGUST = 'AUGUST',
        SEPTEMBER = 'SEPTEMBER',
        OCTOBER = 'OCTOBER',
        NOVEMBER = 'NOVEMBER',
        DECEMBER = 'DECEMBER',
    }


}

