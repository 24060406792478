/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsentStatusUpdateRequest } from '../models/ConsentStatusUpdateRequest';
import type { SuccessCollectionConsentStatusResponse } from '../models/SuccessCollectionConsentStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConsentControllerService {

    /**
     * Returns the status for all consents for the given user.
     * @returns SuccessCollectionConsentStatusResponse OK
     * @throws ApiError
     */
    public static listConsentStatuses(): CancelablePromise<SuccessCollectionConsentStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/consents',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Updates the status for a collection of consents. Consent revocation will be effective only if the consent is currently ACCEPTED.
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static updateConsentStatus(
        requestBody: Array<ConsentStatusUpdateRequest>,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/consents',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns the content for a given consent version.
     * @param name
     * @param version
     * @param language
     * @returns any OK
     * @throws ApiError
     */
    public static getConsentDocumentContent(
        name: 'TERMS_OF_SERVICE' | 'PRIVACY_POLICY' | 'HEALTH_INFORMATION_RELEASE' | 'TELEHEALTH' | 'REFUNDS_FULFILLMENT_SHIPPING_POLICIES' | 'EMAIL_SMS_COMMUNICATIONS' | 'MEDICAL_GROUP_NOTICE_OF_PRIVACY_PRACTICES',
        version: number,
        language?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/consents/documents/{name}/{version}',
            path: {
                'name': name,
                'version': version,
            },
            query: {
                'language': language,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns the content for a given consent version.
     * @param name
     * @param language
     * @returns any OK
     * @throws ApiError
     */
    public static getConsentDocumentContent1(
        name: 'TERMS_OF_SERVICE' | 'PRIVACY_POLICY' | 'HEALTH_INFORMATION_RELEASE' | 'TELEHEALTH' | 'REFUNDS_FULFILLMENT_SHIPPING_POLICIES' | 'EMAIL_SMS_COMMUNICATIONS' | 'MEDICAL_GROUP_NOTICE_OF_PRIVACY_PRACTICES',
        language?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/consents/documents/{name}',
            path: {
                'name': name,
            },
            query: {
                'language': language,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
