/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Provides a MFA secret.
 */
export type RetrieveMfaCredentialResponse = {
    /**
     * The type of credential to be registered.
     */
    type: RetrieveMfaCredentialResponse.type;
    /**
     * The credential secret. Format is depending on the type.
     */
    secret: string;
};

export namespace RetrieveMfaCredentialResponse {

    /**
     * The type of credential to be registered.
     */
    export enum type {
        RFC6238 = 'RFC6238',
    }


}

