/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Response that represents the current status of a consent for a given user.
 */
export type ConsentStatusResponse = {
    /**
     * The consent identifier.
     */
    consent: ConsentStatusResponse.consent;
    /**
     * The consent version.
     */
    version: number;
    /**
     * The current consent acceptance status.
     */
    status: ConsentStatusResponse.status;
    /**
     * If the consent has ever been accepted, the most recent acceptance timestamp.
     */
    acceptedAt: string;
    /**
     * If the consent has been revoked, the most recent revocation timestamp.
     */
    revokedAt: string;
};

export namespace ConsentStatusResponse {

    /**
     * The consent identifier.
     */
    export enum consent {
        TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
        PRIVACY_POLICY = 'PRIVACY_POLICY',
        HEALTH_INFORMATION_RELEASE = 'HEALTH_INFORMATION_RELEASE',
        TELEHEALTH = 'TELEHEALTH',
        REFUNDS_FULFILLMENT_SHIPPING_POLICIES = 'REFUNDS_FULFILLMENT_SHIPPING_POLICIES',
        EMAIL_SMS_COMMUNICATIONS = 'EMAIL_SMS_COMMUNICATIONS',
        MEDICAL_GROUP_NOTICE_OF_PRIVACY_PRACTICES = 'MEDICAL_GROUP_NOTICE_OF_PRIVACY_PRACTICES',
    }

    /**
     * The current consent acceptance status.
     */
    export enum status {
        NOT_ACCEPTED = 'NOT_ACCEPTED',
        ACCEPTED = 'ACCEPTED',
        REVOKED = 'REVOKED',
    }


}

