/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeEmailRequest } from '../models/ChangeEmailRequest';
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { LoginRequest } from '../models/LoginRequest';
import type { RegisterUserRequest } from '../models/RegisterUserRequest';
import type { ResetPasswordConfirmRequest } from '../models/ResetPasswordConfirmRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { SuccessChangeEmailResponse } from '../models/SuccessChangeEmailResponse';
import type { SuccessLoginResponse } from '../models/SuccessLoginResponse';
import type { SuccessRefreshAccessTokenResponse } from '../models/SuccessRefreshAccessTokenResponse';
import type { SuccessRegisterUserResponse } from '../models/SuccessRegisterUserResponse';
import type { SuccessResetPasswordResponse } from '../models/SuccessResetPasswordResponse';
import type { VerifyEmailRequest } from '../models/VerifyEmailRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * Initiates a user registration flow.
     * This will result in sending a verification email, which is used to complete the registration.
     * @param requestBody
     * @returns SuccessRegisterUserResponse The user registration flow has been initiated.
     * @throws ApiError
     */
    public static register(
        requestBody: RegisterUserRequest,
    ): CancelablePromise<SuccessRegisterUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Allows a client to refresh their access token by providing a refresh token with the request.
     * Allows a client to refresh their access token by providing a refresh token with the request. The refresh token must be used as `Bearer` token in the `Authorization` header. The request body must be empty.
     * @returns SuccessRefreshAccessTokenResponse An access token that can be used to make subsequent requests, in the form of a JWT token.
     * @throws ApiError
     */
    public static refreshToken(): CancelablePromise<SuccessRefreshAccessTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/token/refresh',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Changes a user's password.
     * For this to be successful, the following conditions must be true:
     * - the user must exist
     * - the current user's password must be provided
     * - the new password must match the general password requirements
     * - the new password must not be equal to the current one
     * Optionally, all sessions (except the current one) can be invalidated, effectively logging the user out of all devices other than the current one. A confirmation email is sent to inform the user about the password change.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static changePassword(
        requestBody: ChangePasswordRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Initiates a reset password procedure.
     * The password reset is triggered by sending an email address as part of this request. The service will send a confirmation email to the provided address, and will return a verification token. In a second step, the client needs to send a request to `/me/password/reset/confirm` in order to complete the password reset procedure. This request must be sent without any kind of valid authentication credentials.
     * @param requestBody
     * @returns SuccessResetPasswordResponse The password reset procedure was initiated.
     * @throws ApiError
     */
    public static initiatePasswordReset(
        requestBody: ResetPasswordRequest,
    ): CancelablePromise<SuccessResetPasswordResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/password/reset',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The procedure was not initiated because valid credentials were provided with the request.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Completes a password reset procedure.
     * The password reset is completed by posting the email address, verification token, verification code, and the new password to this endpoint. Upon successful completion, the user's password will be set to the provided one, and all user sessions will be ended.
     * @param requestBody
     * @returns string The password reset procedure was successfully completed.
     * @throws ApiError
     */
    public static confirmPasswordReset(
        requestBody: ResetPasswordConfirmRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/password/reset/confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The password reset procedure was not successfully completed because of input data validation error`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Changes a user's email address.
     * Initiates an email change action, which leads to the sending of a confirmation email to the new email address, similar to the one sent during registration.
     * @param requestBody
     * @returns SuccessChangeEmailResponse OK
     * @throws ApiError
     */
    public static changeEmail(
        requestBody: ChangeEmailRequest,
    ): CancelablePromise<SuccessChangeEmailResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Logs the user out of the current session.
     * The session associated with the token will be invalidated, therefore  it is not possible anymore to refresh access tokens for this session. Note that access tokens are not necessarily invalidated, hence the client should still remove all associated tokens. The request body must be empty.
     * @returns void
     * @throws ApiError
     */
    public static logout(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/logout',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Allows a client to login by providing email address, password, and (optionally) a role.
     * This operation exchanges user credentials (email address, password, optional role) with refresh and access tokens, which can subsequently be used to make authenticated requests on behalf of the user, by providing them as `Bearer` tokens in the `Authorization` header.
     * @param requestBody
     * @returns SuccessLoginResponse A pair of refresh token and access token in the form of JWT tokens.
     * @throws ApiError
     */
    public static login(
        requestBody: LoginRequest,
    ): CancelablePromise<SuccessLoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `User is not authorized, or MFA is required.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * @deprecated
     * Verifies an email address.
     * Verifies an email and therefore completes the user registration. For this to be successful,all elements (email, verification code, verification token) must be present, non-expired, and valid.
     * @param requestBody
     * @returns string The verification was successful and the email address has been assigned.
     * @throws ApiError
     */
    public static verifyEmail(
        requestBody: VerifyEmailRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/email/verifications',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Registers an anonymous user account.
     * Creates an anonymous account which can be used for various purposes, but the account is not identified by an email address and password. Hence, it is not possible to log into this account. The only option to use this account is through the returned credentials (refresh and access token) which will expire soon.
     * @returns SuccessLoginResponse The verification was successful and the anonymous account can be used.
     * @throws ApiError
     */
    public static registerAnonymously(): CancelablePromise<SuccessLoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/anonymous',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
