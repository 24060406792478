/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessGetAddressResponseItem } from '../models/SuccessGetAddressResponseItem';
import type { SuccessGetUserAddressesResponse } from '../models/SuccessGetUserAddressesResponse';
import type { UserAddressApiDto } from '../models/UserAddressApiDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddressesControllerService {

    /**
     * Returns default address of given type, or 404 if not found
     * @param addressType Type of the address
     * @returns SuccessGetAddressResponseItem Default address retrieved successfully.
     * @throws ApiError
     */
    public static getDefaultAddress(
        addressType: 'SHIPMENT' | 'BILLING',
    ): CancelablePromise<SuccessGetAddressResponseItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/addresses/{addressType}/default',
            path: {
                'addressType': addressType,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `Default address not found.`,
            },
        });
    }

    /**
     * Stores user address of given type as default, replaces existing one
     * @param addressType Type of the address
     * @param requestBody
     * @returns any Default address stored succesfully.
     * @throws ApiError
     */
    public static putDefaultAddress(
        addressType: 'SHIPMENT' | 'BILLING',
        requestBody: UserAddressApiDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/users/me/addresses/{addressType}/default',
            path: {
                'addressType': addressType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Deletes given address
     * @param addressType Type of the address
     * @returns string Address deleted succesfully.
     * @throws ApiError
     */
    public static deleteDefaultAddress(
        addressType: 'SHIPMENT' | 'BILLING',
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/users/me/addresses/{addressType}/default',
            path: {
                'addressType': addressType,
            },
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns user addresses of given type, or empty collection if not found
     * @param addressType Type of the address
     * @returns SuccessGetUserAddressesResponse Request executed succesfully.
     * @throws ApiError
     */
    public static getUserAddresses(
        addressType: 'SHIPMENT' | 'BILLING',
    ): CancelablePromise<SuccessGetUserAddressesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/addresses/{addressType}',
            path: {
                'addressType': addressType,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Stores new user address of given type
     * @param addressType Type of the address
     * @param requestBody
     * @returns any Address stored successfully.
     * @throws ApiError
     */
    public static postAddress(
        addressType: 'SHIPMENT' | 'BILLING',
        requestBody: UserAddressApiDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/me/addresses/{addressType}',
            path: {
                'addressType': addressType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Deletes given address
     * @param addressType Type of the address
     * @param addressIndex Index of the address to be deleted
     * @returns string Address deleted succesfully.
     * @throws ApiError
     */
    public static deleteAddress(
        addressType: 'SHIPMENT' | 'BILLING',
        addressIndex: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/users/me/addresses/{addressType}/{addressIndex}',
            path: {
                'addressType': addressType,
                'addressIndex': addressIndex,
            },
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Updates given address.
     * @param addressType Type of the address
     * @param addressIndex Index of the address to be updated
     * @param requestBody
     * @returns string Address updated succesfully.
     * @throws ApiError
     */
    public static updateAddress(
        addressType: 'SHIPMENT' | 'BILLING',
        addressIndex: number,
        requestBody: UserAddressApiDto,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/users/me/addresses/{addressType}/{addressIndex}',
            path: {
                'addressType': addressType,
                'addressIndex': addressIndex,
            },
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
