/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PharmacyBenefitsRequest } from '../models/PharmacyBenefitsRequest';
import type { SuccessPharmacyBenefitsResponse } from '../models/SuccessPharmacyBenefitsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PharmacyBenefitsControllerService {

    /**
     * Allows an authenticated user to fetch insurance(s) and/or manufacturer card(s) information.
     * @returns SuccessPharmacyBenefitsResponse OK
     * @throws ApiError
     */
    public static fetchPharmacyBenefitsByUser(): CancelablePromise<SuccessPharmacyBenefitsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/benefits/pharmacy',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Allows an authenticated user to store/modify pharmacy benefits - insurance(s) and/or manufacturer card(s).
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static createOrUpdateBenefitsByUser(
        requestBody: PharmacyBenefitsRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/benefits/pharmacy',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'X-NineamHealth-TraceId',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
