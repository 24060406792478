/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessVoid } from '../models/SuccessVoid';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LabValuesControllerService {

    /**
     * Allows a user to store lab values for themselves. In case this value exists already, an error will be returned.
     * @param requestBody
     * @returns SuccessVoid OK
     * @throws ApiError
     */
    public static storeSelfReportedLabValues(
        requestBody: any,
    ): CancelablePromise<SuccessVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/labs/values',
            body: requestBody,
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Allows the client to store lab values coming from the user's device. In case this value exists already, an error will be returned.
     * @param requestBody
     * @returns SuccessVoid OK
     * @throws ApiError
     */
    public static storeDeviceLabValues(
        requestBody: any,
    ): CancelablePromise<SuccessVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/labs/values/device',
            body: requestBody,
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
