/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CarePlanActionRequest } from './CarePlanActionRequest';

export type StopMedicationPrescribingCarePlanActionRequest = (CarePlanActionRequest & {
    /**
     * Stop cause
     */
    stopCause?: StopMedicationPrescribingCarePlanActionRequest.stopCause;
    /**
     * Stop reason note
     */
    stopNote?: string;
    /**
     * NDC of item that should be stopped.
     */
    ndc?: string;
    /**
     * Id of item that should be stopped.
     */
    stoppedFromPurchaseItemId?: string;
} & {
    /**
     * Stop cause
     */
    stopCause: StopMedicationPrescribingCarePlanActionRequest.stopCause;
});

export namespace StopMedicationPrescribingCarePlanActionRequest {

    /**
     * Stop cause
     */
    export enum stopCause {
        NEW_DOSE = 'NEW_DOSE',
        SIDE_EFFECTS = 'SIDE_EFFECTS',
        MEDICATION_ENTERED_IN_ERROR_BY_PATIENT = 'MEDICATION_ENTERED_IN_ERROR_BY_PATIENT',
        MEDICATION_INTAKE_DISCONTINUED_BY_OUTSIDE_PROVIDER = 'MEDICATION_INTAKE_DISCONTINUED_BY_OUTSIDE_PROVIDER',
        NEW_DOSAGE_PRESCRIBED_BY_OUTSIDE_PROVIDER = 'NEW_DOSAGE_PRESCRIBED_BY_OUTSIDE_PROVIDER',
        NEW_DOSAGE_RECOMMENDED_TO_OUTSIDE_PROVIDER = 'NEW_DOSAGE_RECOMMENDED_TO_OUTSIDE_PROVIDER',
        MOVED_TO_9AM = 'MOVED_TO_9AM',
        OTHER = 'OTHER',
    }


}

