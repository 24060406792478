/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Details about individual tests which are part of a lab order
 */
export type LabOrderStatusHistoryItemResponse = {
    /**
     * The status history item id.
     */
    id: string;
    /**
     * The changed status.
     */
    status: LabOrderStatusHistoryItemResponse.status;
    /**
     * The date when status changed.
     */
    timestamp: string;
};

export namespace LabOrderStatusHistoryItemResponse {

    /**
     * The changed status.
     */
    export enum status {
        ORDERED = 'ORDERED',
        PENDING = 'PENDING',
        WAITING_FOR_LAB_REQUISITION_UPLOAD = 'WAITING_FOR_LAB_REQUISITION_UPLOAD',
        PROCESSING = 'PROCESSING',
        CANCELED = 'CANCELED',
        DECLINED = 'DECLINED',
        SHIPMENT_INITIATED = 'SHIPMENT_INITIATED',
        WAITING_FOR_PATIENT_TO_SCHEDULE_APPOINTMENT = 'WAITING_FOR_PATIENT_TO_SCHEDULE_APPOINTMENT',
        WAITING_FOR_PATIENT_TO_RESCHEDULE_APPOINTMENT = 'WAITING_FOR_PATIENT_TO_RESCHEDULE_APPOINTMENT',
        APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
        SHIPPING_TO_PATIENT = 'SHIPPING_TO_PATIENT',
        DELIVERED_TO_PATIENT = 'DELIVERED_TO_PATIENT',
        KIT_SENT_TO_LAB = 'KIT_SENT_TO_LAB',
        KIT_DELIVERED_TO_LAB = 'KIT_DELIVERED_TO_LAB',
        SPECIMENS_COLLECTED = 'SPECIMENS_COLLECTED',
        RESULTS_AVAILABLE = 'RESULTS_AVAILABLE',
        COMPLETED = 'COMPLETED',
    }


}

