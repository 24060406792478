/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReportConsultationIssueRequest = {
    /**
     * The consultation ID.
     */
    consultationId: string;
    /**
     * The consultation issue type.
     */
    issueType: ReportConsultationIssueRequest.issueType;
    /**
     * The issue details.
     */
    note?: string;
};

export namespace ReportConsultationIssueRequest {

    /**
     * The consultation issue type.
     */
    export enum issueType {
        CLINICAL_ISSUE = 'Clinical issue',
        TECHNICAL_ISSUE = 'Technical issue',
        ISSUE_WITH_ID_VERIFICATION = 'Issue with ID verification',
        OTHER_ISSUE = 'Other issue',
    }


}

