/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Subscription status history items.
 */
export type SubscriptionStatusHistoryItemResponse = {
    /**
     * The status history item id.
     */
    id: string;
    /**
     * The changed status.
     */
    status: SubscriptionStatusHistoryItemResponse.status;
    /**
     * The date when status changed.
     */
    timestamp: string;
};

export namespace SubscriptionStatusHistoryItemResponse {

    /**
     * The changed status.
     */
    export enum status {
        DRAFT = 'DRAFT',
        IN_REVIEW = 'IN_REVIEW',
        ACTIVE = 'ACTIVE',
        REJECTED = 'REJECTED',
        FINISHED = 'FINISHED',
        PAUSED = 'PAUSED',
    }


}

