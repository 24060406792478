/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessGetMyProfileValuesResponse } from '../models/SuccessGetMyProfileValuesResponse';
import type { SuccessMedicationsResponse } from '../models/SuccessMedicationsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfileControllerService {

    /**
     * Returns attribute values from the patient profile.
     * @param attribute
     * @returns SuccessGetMyProfileValuesResponse OK
     * @throws ApiError
     */
    public static getMyProfileAttributeValues(
        attribute: Array<string>,
    ): CancelablePromise<SuccessGetMyProfileValuesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/profile2/values',
            query: {
                'attribute': attribute,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns a user's medication from the patient profile.
     * This endpoint returns all medications that are part of the patients medical profile.
     * @returns SuccessMedicationsResponse Fetched medications successfully.
     * @throws ApiError
     */
    public static getMedications(): CancelablePromise<SuccessMedicationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me/profile2/medications',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
