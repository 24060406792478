/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CarePlanActionRequest } from './CarePlanActionRequest';

/**
 * Supply data to stop care plan action
 */
export type StopSupplyCarePlanActionRequest = (CarePlanActionRequest & {
    /**
     * Stop cause
     */
    stopCause?: StopSupplyCarePlanActionRequest.stopCause;
    /**
     * Stop reason note
     */
    stopNote?: string;
    /**
     * NDC of item that should be stopped.
     */
    ndc?: string;
} & {
    /**
     * Stop cause
     */
    stopCause: StopSupplyCarePlanActionRequest.stopCause;
    /**
     * NDC of item that should be stopped.
     */
    ndc: string;
});

export namespace StopSupplyCarePlanActionRequest {

    /**
     * Stop cause
     */
    export enum stopCause {
        NEW_DOSE = 'NEW_DOSE',
        SIDE_EFFECTS = 'SIDE_EFFECTS',
        MEDICATION_ENTERED_IN_ERROR_BY_PATIENT = 'MEDICATION_ENTERED_IN_ERROR_BY_PATIENT',
        MEDICATION_INTAKE_DISCONTINUED_BY_OUTSIDE_PROVIDER = 'MEDICATION_INTAKE_DISCONTINUED_BY_OUTSIDE_PROVIDER',
        NEW_DOSAGE_PRESCRIBED_BY_OUTSIDE_PROVIDER = 'NEW_DOSAGE_PRESCRIBED_BY_OUTSIDE_PROVIDER',
        NEW_DOSAGE_RECOMMENDED_TO_OUTSIDE_PROVIDER = 'NEW_DOSAGE_RECOMMENDED_TO_OUTSIDE_PROVIDER',
        MOVED_TO_9AM = 'MOVED_TO_9AM',
        OTHER = 'OTHER',
    }


}

