/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data for adding item to subscription
 */
export type AddSubscriptionItemRequest = {
    /**
     * Provider for the given product
     */
    provider: AddSubscriptionItemRequest.provider;
    /**
     * Sku of product/item to add
     */
    sku: string;
    /**
     * The quantity of item
     */
    quantity?: number;
};

export namespace AddSubscriptionItemRequest {

    /**
     * Provider for the given product
     */
    export enum provider {
        OTC = 'OTC',
        TREATMENT_PLAN = 'TREATMENT_PLAN',
        PRESCRIPTION = 'PRESCRIPTION',
    }


}

