/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessAppointmentInitiateReschedulingResponse } from '../models/SuccessAppointmentInitiateReschedulingResponse';
import type { SuccessCollectionAppointmentResponse } from '../models/SuccessCollectionAppointmentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppointmentControllerService {

    /**
     * Marks an appointment as rescheduling. Does not change any other attribute, including status.
     * @param appointmentId
     * @returns SuccessAppointmentInitiateReschedulingResponse OK
     * @throws ApiError
     */
    public static initiateRescheduling(
        appointmentId: string,
    ): CancelablePromise<SuccessAppointmentInitiateReschedulingResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/appointments/{appointmentId}/reschedule',
            path: {
                'appointmentId': appointmentId,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns an .ics file for the given appointment.
     * @param appointmentId
     * @param accept The content type to be expected by the client
     * @param authToken An optional short-lived authentication token
     * @returns string OK
     * @throws ApiError
     */
    public static getAppointmentIcsFile(
        appointmentId: string,
        accept?: any,
        authToken?: any,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/appointments/{appointmentId}.ics',
            path: {
                'appointmentId': appointmentId,
            },
            headers: {
                'Accept': accept,
            },
            query: {
                'authToken': authToken,
            },
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

    /**
     * Returns all scheduled appointments for the current user.
     * @returns SuccessCollectionAppointmentResponse OK
     * @throws ApiError
     */
    public static getScheduledAppointments(): CancelablePromise<SuccessCollectionAppointmentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/appointments/scheduled',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
