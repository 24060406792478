/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data needed for removing an item from an existing subscription
 */
export type AdminSubscriptionRemoveItemRequest = {
    /**
     * The subscription ID
     */
    subscriptionId: string;
    /**
     * The item ID
     */
    itemId: string;
    /**
     * The reason why this item is being removed.
     */
    reason: AdminSubscriptionRemoveItemRequest.reason;
};

export namespace AdminSubscriptionRemoveItemRequest {

    /**
     * The reason why this item is being removed.
     */
    export enum reason {
        ALTERNATIVE_MEDICATION_SUPPLIER = 'ALTERNATIVE_MEDICATION_SUPPLIER',
        MEDICATION_ENTERED_INCORRECT_BY_PATIENT = 'MEDICATION_ENTERED_INCORRECT_BY_PATIENT',
        MEDICATION_INTAKE_DISCONTINUED = 'MEDICATION_INTAKE_DISCONTINUED',
        MEDICATION_INTAKE_DISCONTINUED_BY_OUTSIDE_PROVIDER = 'MEDICATION_INTAKE_DISCONTINUED_BY_OUTSIDE_PROVIDER',
        MEDICATION_NOT_PRESCRIBED = 'MEDICATION_NOT_PRESCRIBED',
        MEDICATION_NOT_WANTED = 'MEDICATION_NOT_WANTED',
        MEDICATION_SUPPLY_STILL_AVAILABLE = 'MEDICATION_SUPPLY_STILL_AVAILABLE',
        NEW_DOSAGE_PRESCRIBED = 'NEW_DOSAGE_PRESCRIBED',
        NEW_DOSAGE_PRESCRIBED_BY_OUTSIDE_PROVIDER = 'NEW_DOSAGE_PRESCRIBED_BY_OUTSIDE_PROVIDER',
        NEW_DOSAGE_RECOMMENDED_TO_OUTSIDE_PROVIDER = 'NEW_DOSAGE_RECOMMENDED_TO_OUTSIDE_PROVIDER',
        PRELIMINARY_MEDICATION_NOT_NEEDED_ANYMORE = 'PRELIMINARY_MEDICATION_NOT_NEEDED_ANYMORE',
        PRELIMINARY_MEDICATION_REPLACED = 'PRELIMINARY_MEDICATION_REPLACED',
        SIDE_EFFECTS = 'SIDE_EFFECTS',
        AUTOMATIC_LAB_ITEM_UPDATE = 'AUTOMATIC_LAB_ITEM_UPDATE',
        LAB_FROM_DIFFERENT_PROVIDER = 'LAB_FROM_DIFFERENT_PROVIDER',
        LAB_TESTS_NOT_SELECTED = 'LAB_TESTS_NOT_SELECTED',
        ADMIN = 'ADMIN',
        EXPIRATION_DATE_REACHED = 'EXPIRATION_DATE_REACHED',
        METADATA_CHANGED = 'METADATA_CHANGED',
        NEW_TREATMENT_PLAN_EVIDENCE_AVAILABLE = 'NEW_TREATMENT_PLAN_EVIDENCE_AVAILABLE',
        ONE_TIME_ITEM_FINISHED = 'ONE_TIME_ITEM_FINISHED',
        MOVED_TO_9AM = 'MOVED_TO_9AM',
        UNKNOWN = 'UNKNOWN',
    }


}

