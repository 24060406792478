/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Lists all providers and lab tests with a flag for providers that are available for the requested user.
 */
export type AdminAvailableLabProvidersResponse = {
    /**
     * The lab order provider.
     */
    labOrderProvider?: AdminAvailableLabProvidersResponse.labOrderProvider;
    availableForUser?: boolean;
    /**
     * The list of available lab tests for this provider.
     */
    availableLabTests?: Array<'A1C' | 'ALANINE_TRANSAMINASE' | 'ASPARTATE_AMINOTRANSFERASE' | 'BMP' | 'CMP' | 'CREATININE' | 'C_PEPTIDE_SERUM' | 'DIABETES_PANEL' | 'FRUCTOSAMINE' | 'GAD65' | 'GLUCOSE' | 'HEPATIC_FUNCTION_PANEL' | 'LIPIDS' | 'POTASSIUM' | 'SODIUM' | 'THYROID_STIMULATING_HORMONE' | 'TOTAL_CHOLESTEROL' | 'TRIGLYCERIDES' | 'URINARY_ALBUMIN_TO_CREATININE_RATIO'>;
};

export namespace AdminAvailableLabProvidersResponse {

    /**
     * The lab order provider.
     */
    export enum labOrderProvider {
        TRUEPILL = 'TRUEPILL',
        BIO_REFERENCE_LAB = 'BIO_REFERENCE_LAB',
        PATIENT = 'PATIENT',
        ZENDESK = 'ZENDESK',
        GETLABS = 'GETLABS',
        QUEST = 'QUEST',
        LABCORP = 'LABCORP',
        EVERLYWELL = 'EVERLYWELL',
        TASSO = 'TASSO',
    }


}

