/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Response for admin user operation requests.
 */
export type AdminUserResponse = {
    id?: string;
    createdAt?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    email?: string;
    emailVerifiedAt?: string;
    lastConsultationStatus?: AdminUserResponse.lastConsultationStatus;
    subscriptionStatus?: AdminUserResponse.subscriptionStatus;
    subscriptionStartDate?: string;
    sex?: string;
    activePayerId?: string;
    activeEmployerId?: string;
    programMemberships?: Array<string>;
};

export namespace AdminUserResponse {

    export enum lastConsultationStatus {
        DRAFT = 'DRAFT',
        ACTIVE = 'ACTIVE',
        COMPLETED = 'COMPLETED',
        ISSUE = 'ISSUE',
        REJECTED = 'REJECTED',
        CANCELLED = 'CANCELLED',
        WAITING_FOR_CARE_TEAM = 'WAITING_FOR_CARE_TEAM',
    }

    export enum subscriptionStatus {
        DRAFT = 'DRAFT',
        IN_REVIEW = 'IN_REVIEW',
        ACTIVE = 'ACTIVE',
        REJECTED = 'REJECTED',
        FINISHED = 'FINISHED',
        PAUSED = 'PAUSED',
    }


}

