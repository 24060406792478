/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data to trigger medication prior auth or appeal.
 */
export type StoreMedicationPriorAuthorizationRequest = {
    /**
     * Reflects the status of the PA
     */
    status: StoreMedicationPriorAuthorizationRequest.status;
    /**
     * Status description for the PA
     */
    statusDescription?: string;
    /**
     * Status comment for the PA
     */
    comment?: string;
    /**
     * The date until the PA is valid
     */
    validUntil?: string;
    /**
     * Document ids related with status change of the PA
     */
    attachments?: Array<string>;
    /**
     * ID of the user who the PA is for
     */
    userId: string;
    /**
     * Name of the medication which the PA is for
     */
    medicationName?: string;
    /**
     * If it is an appeal, this must contain the ID of the corresponding initial PA
     */
    appealFor?: string;
    /**
     * The date when the PA was initiated / or appealed
     */
    initiatedAt: string;
    /**
     * A url that leads to the PA on the Cover My Meds Website
     */
    coverMyMedsUrl?: string;
};

export namespace StoreMedicationPriorAuthorizationRequest {

    /**
     * Reflects the status of the PA
     */
    export enum status {
        WAITING_ON_PHARMACY = 'WAITING_ON_PHARMACY',
        NEW = 'NEW',
        CREATED = 'CREATED',
        SENT_TO_PLAN = 'SENT_TO_PLAN',
        NOT_SENT_TO_PLAN = 'NOT_SENT_TO_PLAN',
        OUTCOME_UNKNOWN = 'OUTCOME_UNKNOWN',
        PATIENT_NOT_COVERED_BY_PLAN = 'PATIENT_NOT_COVERED_BY_PLAN',
        APPROVED = 'APPROVED',
        DENIED = 'DENIED',
    }


}

