/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessGetShipmentsResponse } from '../models/SuccessGetShipmentsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShipmentControllerService {

    /**
     * Provides information about user shipments.
     * Provides data of all shipments available for authenticated user.
     * @returns SuccessGetShipmentsResponse Shipments loaded successfully.
     * @throws ApiError
     */
    public static allShipments(): CancelablePromise<SuccessGetShipmentsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/shipments',
            errors: {
                400: `Some input data was invalid. The \`validationErrors\` field contains a list of errors that give more details.`,
                401: `The client is not authorized to perform the action. The \`code\`, \`message\`, and \`data\` fields may provide more information about the reason of the error.`,
                404: `The requested entity was not found, or the client has no permission to access the entity.`,
            },
        });
    }

}
