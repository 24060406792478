/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data to register a new device for the given user.
 */
export type RegisterDeviceRequest = {
    /**
     * The external device ID for this device.
     */
    externalDeviceId: string;
    /**
     * The type of device
     */
    type: RegisterDeviceRequest.type;
    /**
     * The device model identifier (if it is known).
     */
    model?: RegisterDeviceRequest.model;
    /**
     * The name of the model. Either `model` or `modelName` must be specified.
     */
    modelName?: string;
    /**
     * Additional information to be stored with the device (e.g. the device name)
     */
    comment?: string;
};

export namespace RegisterDeviceRequest {

    /**
     * The type of device
     */
    export enum type {
        BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
        GLUCOMETER = 'GLUCOMETER',
        CGM = 'CGM',
        WEIGHT_SCALE = 'WEIGHT_SCALE',
        TAPE_MEASURE = 'TAPE_MEASURE',
        MOBILE_PHONE = 'MOBILE_PHONE',
    }

    /**
     * The device model identifier (if it is known).
     */
    export enum model {
        SMARTMETER_SM5000IB = 'SMARTMETER_SM5000IB',
        SMARTMETER_UA651CELSM = 'SMARTMETER_UA651CELSM',
        SMARTMETER_IBP15 = 'SMARTMETER_IBP15',
        MIOCONNECT_BPM = 'MIOCONNECT_BPM',
        SMARTHEART_BPM_845717009201 = 'SMARTHEART_BPM_845717009201',
        OTHER_BP = 'OTHER_BP',
        SMARTMETER_GM291 = 'SMARTMETER_GM291',
        SMARTMETER_GM291V = 'SMARTMETER_GM291V',
        SMARTMETER_TELEBGM = 'SMARTMETER_TELEBGM',
        ASCENSIA_CONTOUR = 'ASCENSIA_CONTOUR',
        ONETOUCH_VERIO = 'ONETOUCH_VERIO',
        TRANSTEK_GLUCOMETER = 'TRANSTEK_GLUCOMETER',
        OTHER_BGM = 'OTHER_BGM',
        ABBOTT_FREESTYLE_LIBRE = 'ABBOTT_FREESTYLE_LIBRE',
        DEXCOM_GX = 'DEXCOM_GX',
        OTHER_CGM = 'OTHER_CGM',
        SMARTMETER_SMCLBS = 'SMARTMETER_SMCLBS',
        SMARTMETER_TELEWEIGHT = 'SMARTMETER_TELEWEIGHT',
        MIOCONNECT_SCALE = 'MIOCONNECT_SCALE',
        OTHER_WEIGHTSCALE = 'OTHER_WEIGHTSCALE',
        OTHER_TAPE = 'OTHER_TAPE',
        OTHER_IPHONE = 'OTHER_IPHONE',
        OTHER_ANDROID_PHONE = 'OTHER_ANDROID_PHONE',
    }


}

