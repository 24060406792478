/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Contains information about a shipment item.
 */
export type AdminShipmentItemResponse = {
    id: string;
    quantity: number;
    sku: string;
    type: AdminShipmentItemResponse.type;
    reference: string;
};

export namespace AdminShipmentItemResponse {

    export enum type {
        MEDICATION = 'MEDICATION',
        LAB_TEST_KIT = 'LAB_TEST_KIT',
        TEST_STRIP = 'TEST_STRIP',
        GLUCOMETER = 'GLUCOMETER',
        LANCET = 'LANCET',
        COMPLEMENT = 'COMPLEMENT',
        WELCOME_KIT = 'WELCOME_KIT',
        BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
        CONNECTED_WEIGHT_SCALE = 'CONNECTED_WEIGHT_SCALE',
    }


}

