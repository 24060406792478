/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information about a partner.
 */
export type ListPartnersResponseItem = {
    /**
     * The unique ID for this partner.
     */
    id: string;
    /**
     * The human-readable name for this partner.
     */
    name: string;
    /**
     * A list of employers served through this partner.
     */
    servedEmployers?: Array<ListPartnersResponseItem>;
    /**
     * The partner type.
     */
    type: ListPartnersResponseItem.type;
};

export namespace ListPartnersResponseItem {

    /**
     * The partner type.
     */
    export enum type {
        COMMERCIAL_HEALTH_PLAN = 'COMMERCIAL_HEALTH_PLAN',
        DTC_CASH_PAY = 'DTC_CASH_PAY',
        DTC_INSURANCE = 'DTC_INSURANCE',
        EMPLOYER_DIRECT = 'EMPLOYER_DIRECT',
        EMPLOYER_INDIRECT = 'EMPLOYER_INDIRECT',
        MA_HEALTH_PLAN = 'MA_HEALTH_PLAN',
        NAVIGATOR = 'NAVIGATOR',
    }


}

