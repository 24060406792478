/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PurchasableItem } from './PurchasableItem';

export type TreatmentPlanPurchaseItemSuggestion = {
    provider?: TreatmentPlanPurchaseItemSuggestion.provider;
    id?: string;
    type?: TreatmentPlanPurchaseItemSuggestion.type;
    sku?: string;
    replacementSkus?: Array<string>;
    metadata?: Record<string, any>;
    daysOfTaking?: string;
    defaultInGivenRenewalIntervals?: Array<string>;
    expiredAt?: string;
    expirationReason?: TreatmentPlanPurchaseItemSuggestion.expirationReason;
    typeName?: TreatmentPlanPurchaseItemSuggestion.typeName;
    itemId?: string;
    priceQuantity?: number;
    requiredSkus?: Array<string>;
    relatedPurchasableItems?: Array<PurchasableItem>;
    shipmentPeriod?: string;
    availableQuantities?: Array<number>;
    relatedRemovalPurchasableItems?: Array<PurchasableItem>;
};

export namespace TreatmentPlanPurchaseItemSuggestion {

    export enum provider {
        OTC = 'OTC',
        TREATMENT_PLAN = 'TREATMENT_PLAN',
        PRESCRIPTION = 'PRESCRIPTION',
    }

    export enum type {
        SUBSCRIPTION_PLAN_SUGGESTION = 'SubscriptionPlanSuggestion',
        LAB_TEST_SUGGESTION = 'LabTestSuggestion',
        MEDICATION_SUGGESTION = 'MedicationSuggestion',
        PRELIMINARY_MEDICATION_SUGGESTION = 'PreliminaryMedicationSuggestion',
        PRESCRIBED_MEDICATION = 'PrescribedMedication',
        DOCUMENTED_MEDICATION = 'DocumentedMedication',
        PRESCRIBED_SUPPLY = 'PrescribedSupply',
        OVER_THE_COUNTER_GLUCOMETER = 'OverTheCounterGlucometer',
        OVER_THE_COUNTER_LAB_TEST = 'OverTheCounterLabTest',
        OVER_THE_COUNTER_MICROLET_COLOR_LANCET_ITEM = 'OverTheCounterMicroletColorLancetItem',
        OVER_THE_COUNTER_STRIP_ITEM = 'OverTheCounterStripItem',
        SUBSCRIBED_DEVICE = 'SubscribedDevice',
    }

    export enum expirationReason {
        ALTERNATIVE_MEDICATION_SUPPLIER = 'ALTERNATIVE_MEDICATION_SUPPLIER',
        MEDICATION_ENTERED_INCORRECT_BY_PATIENT = 'MEDICATION_ENTERED_INCORRECT_BY_PATIENT',
        MEDICATION_INTAKE_DISCONTINUED = 'MEDICATION_INTAKE_DISCONTINUED',
        MEDICATION_INTAKE_DISCONTINUED_BY_OUTSIDE_PROVIDER = 'MEDICATION_INTAKE_DISCONTINUED_BY_OUTSIDE_PROVIDER',
        MEDICATION_NOT_PRESCRIBED = 'MEDICATION_NOT_PRESCRIBED',
        MEDICATION_NOT_WANTED = 'MEDICATION_NOT_WANTED',
        MEDICATION_SUPPLY_STILL_AVAILABLE = 'MEDICATION_SUPPLY_STILL_AVAILABLE',
        NEW_DOSAGE_PRESCRIBED = 'NEW_DOSAGE_PRESCRIBED',
        NEW_DOSAGE_PRESCRIBED_BY_OUTSIDE_PROVIDER = 'NEW_DOSAGE_PRESCRIBED_BY_OUTSIDE_PROVIDER',
        NEW_DOSAGE_RECOMMENDED_TO_OUTSIDE_PROVIDER = 'NEW_DOSAGE_RECOMMENDED_TO_OUTSIDE_PROVIDER',
        PRELIMINARY_MEDICATION_NOT_NEEDED_ANYMORE = 'PRELIMINARY_MEDICATION_NOT_NEEDED_ANYMORE',
        PRELIMINARY_MEDICATION_REPLACED = 'PRELIMINARY_MEDICATION_REPLACED',
        SIDE_EFFECTS = 'SIDE_EFFECTS',
        AUTOMATIC_LAB_ITEM_UPDATE = 'AUTOMATIC_LAB_ITEM_UPDATE',
        LAB_FROM_DIFFERENT_PROVIDER = 'LAB_FROM_DIFFERENT_PROVIDER',
        LAB_TESTS_NOT_SELECTED = 'LAB_TESTS_NOT_SELECTED',
        ADMIN = 'ADMIN',
        EXPIRATION_DATE_REACHED = 'EXPIRATION_DATE_REACHED',
        METADATA_CHANGED = 'METADATA_CHANGED',
        NEW_TREATMENT_PLAN_EVIDENCE_AVAILABLE = 'NEW_TREATMENT_PLAN_EVIDENCE_AVAILABLE',
        ONE_TIME_ITEM_FINISHED = 'ONE_TIME_ITEM_FINISHED',
        MOVED_TO_9AM = 'MOVED_TO_9AM',
        UNKNOWN = 'UNKNOWN',
    }

    export enum typeName {
        SUBSCRIPTION_PLAN_SUGGESTION = 'SubscriptionPlanSuggestion',
        LAB_TEST_SUGGESTION = 'LabTestSuggestion',
        MEDICATION_SUGGESTION = 'MedicationSuggestion',
        PRELIMINARY_MEDICATION_SUGGESTION = 'PreliminaryMedicationSuggestion',
        PRESCRIBED_MEDICATION = 'PrescribedMedication',
        DOCUMENTED_MEDICATION = 'DocumentedMedication',
        PRESCRIBED_SUPPLY = 'PrescribedSupply',
        OVER_THE_COUNTER_GLUCOMETER = 'OverTheCounterGlucometer',
        OVER_THE_COUNTER_LAB_TEST = 'OverTheCounterLabTest',
        OVER_THE_COUNTER_MICROLET_COLOR_LANCET_ITEM = 'OverTheCounterMicroletColorLancetItem',
        OVER_THE_COUNTER_STRIP_ITEM = 'OverTheCounterStripItem',
        SUBSCRIBED_DEVICE = 'SubscribedDevice',
    }


}

