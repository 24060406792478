import type { ApiRequestOptions } from "./ApiRequestOptions";
import version from "./version.json" with { type: "json" };

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;

export type OpenAPIConfig = {
  BASE: string;
  VERSION: string;
  WITH_CREDENTIALS: boolean;
  CREDENTIALS: "include" | "omit" | "same-origin";
  TOKEN?: string | Resolver<string>;
  USERNAME?: string | Resolver<string>;
  PASSWORD?: string | Resolver<string>;
  HEADERS?: Headers | Resolver<Headers>;
  ENCODE_PATH?: (path: string) => string;
  SOURCE: {
    user: string;
    url: string;
    domain: string;
  };
};

export const OpenAPI: OpenAPIConfig = {
  BASE: "http://api.dev.9am.health",
  VERSION: version.version,
  WITH_CREDENTIALS: false,
  CREDENTIALS: "include",
  TOKEN: undefined,
  USERNAME: undefined,
  PASSWORD: undefined,
  HEADERS: undefined,
  ENCODE_PATH: undefined,
  SOURCE: {
    user: version.user,
    url: version.url,
    domain: new URL(version.url).hostname,
  },
};
