/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OptionsResponse } from './OptionsResponse';

/**
 * Prescription options for specific subscription period.
 */
export type PrescriptionDraftOptionsResponse = {
    /**
     * Available dosage Forms.
     */
    dosageForms: Array<'CAPSULE' | 'CAPLET' | 'FILM' | 'GUM' | 'IMPLANT' | 'INSERT' | 'LOZENGE' | 'PATCH' | 'RING' | 'SPONGE' | 'STICK' | 'STRIP' | 'SUPPOSITORY' | 'TABLET' | 'APPLICATOR' | 'BLISTER' | 'KIT' | 'PACKET' | 'PAD' | 'SWAB' | 'UNSPECIFIED' | 'WAFER' | 'EACH' | 'GRAM' | 'MILLILITER' | 'UNIT' | 'LANCET' | 'PEN_NEEDLE' | 'TROCHE'>;
    /**
     * Dosage Form.
     */
    defaultDosageForm: PrescriptionDraftOptionsResponse.defaultDosageForm;
    /**
     * Additional info about prescription option
     */
    options: Array<OptionsResponse>;
};

export namespace PrescriptionDraftOptionsResponse {

    /**
     * Dosage Form.
     */
    export enum defaultDosageForm {
        CAPSULE = 'CAPSULE',
        CAPLET = 'CAPLET',
        FILM = 'FILM',
        GUM = 'GUM',
        IMPLANT = 'IMPLANT',
        INSERT = 'INSERT',
        LOZENGE = 'LOZENGE',
        PATCH = 'PATCH',
        RING = 'RING',
        SPONGE = 'SPONGE',
        STICK = 'STICK',
        STRIP = 'STRIP',
        SUPPOSITORY = 'SUPPOSITORY',
        TABLET = 'TABLET',
        APPLICATOR = 'APPLICATOR',
        BLISTER = 'BLISTER',
        KIT = 'KIT',
        PACKET = 'PACKET',
        PAD = 'PAD',
        SWAB = 'SWAB',
        UNSPECIFIED = 'UNSPECIFIED',
        WAFER = 'WAFER',
        EACH = 'EACH',
        GRAM = 'GRAM',
        MILLILITER = 'MILLILITER',
        UNIT = 'UNIT',
        LANCET = 'LANCET',
        PEN_NEEDLE = 'PEN_NEEDLE',
        TROCHE = 'TROCHE',
    }


}

