/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddressDto = {
    street?: string;
    aptSuite?: string;
    city?: string;
    state?: AddressDto.state;
    zip?: string;
    countryCode?: AddressDto.countryCode;
};

export namespace AddressDto {

    export enum state {
        AA = 'AA',
        AE = 'AE',
        AL = 'AL',
        AK = 'AK',
        AP = 'AP',
        AS = 'AS',
        AZ = 'AZ',
        AR = 'AR',
        CA = 'CA',
        CO = 'CO',
        CT = 'CT',
        DE = 'DE',
        DC = 'DC',
        FM = 'FM',
        FL = 'FL',
        GA = 'GA',
        GU = 'GU',
        HI = 'HI',
        ID = 'ID',
        IL = 'IL',
        IN = 'IN',
        IA = 'IA',
        KS = 'KS',
        KY = 'KY',
        LA = 'LA',
        ME = 'ME',
        MD = 'MD',
        MH = 'MH',
        MA = 'MA',
        MI = 'MI',
        MN = 'MN',
        MS = 'MS',
        MO = 'MO',
        MT = 'MT',
        MP = 'MP',
        NE = 'NE',
        NV = 'NV',
        NH = 'NH',
        NJ = 'NJ',
        NM = 'NM',
        NY = 'NY',
        NC = 'NC',
        ND = 'ND',
        OH = 'OH',
        OK = 'OK',
        OR = 'OR',
        PW = 'PW',
        PA = 'PA',
        PR = 'PR',
        RI = 'RI',
        SC = 'SC',
        SD = 'SD',
        TN = 'TN',
        TX = 'TX',
        UT = 'UT',
        VT = 'VT',
        VI = 'VI',
        VA = 'VA',
        WA = 'WA',
        WV = 'WV',
        WI = 'WI',
        WY = 'WY',
    }

    export enum countryCode {
        UNDEFINED = 'UNDEFINED',
        AC = 'AC',
        AD = 'AD',
        AE = 'AE',
        AF = 'AF',
        AG = 'AG',
        AI = 'AI',
        AL = 'AL',
        AM = 'AM',
        AN = 'AN',
        AO = 'AO',
        AQ = 'AQ',
        AR = 'AR',
        AS = 'AS',
        AT = 'AT',
        AU = 'AU',
        AW = 'AW',
        AX = 'AX',
        AZ = 'AZ',
        BA = 'BA',
        BB = 'BB',
        BD = 'BD',
        BE = 'BE',
        BF = 'BF',
        BG = 'BG',
        BH = 'BH',
        BI = 'BI',
        BJ = 'BJ',
        BL = 'BL',
        BM = 'BM',
        BN = 'BN',
        BO = 'BO',
        BQ = 'BQ',
        BR = 'BR',
        BS = 'BS',
        BT = 'BT',
        BU = 'BU',
        BV = 'BV',
        BW = 'BW',
        BY = 'BY',
        BZ = 'BZ',
        CA = 'CA',
        CC = 'CC',
        CD = 'CD',
        CF = 'CF',
        CG = 'CG',
        CH = 'CH',
        CI = 'CI',
        CK = 'CK',
        CL = 'CL',
        CM = 'CM',
        CN = 'CN',
        CO = 'CO',
        CP = 'CP',
        CR = 'CR',
        CS = 'CS',
        CU = 'CU',
        CV = 'CV',
        CW = 'CW',
        CX = 'CX',
        CY = 'CY',
        CZ = 'CZ',
        DE = 'DE',
        DG = 'DG',
        DJ = 'DJ',
        DK = 'DK',
        DM = 'DM',
        DO = 'DO',
        DZ = 'DZ',
        EA = 'EA',
        EC = 'EC',
        EE = 'EE',
        EG = 'EG',
        EH = 'EH',
        ER = 'ER',
        ES = 'ES',
        ET = 'ET',
        EU = 'EU',
        EZ = 'EZ',
        FI = 'FI',
        FJ = 'FJ',
        FK = 'FK',
        FM = 'FM',
        FO = 'FO',
        FR = 'FR',
        FX = 'FX',
        GA = 'GA',
        GB = 'GB',
        GD = 'GD',
        GE = 'GE',
        GF = 'GF',
        GG = 'GG',
        GH = 'GH',
        GI = 'GI',
        GL = 'GL',
        GM = 'GM',
        GN = 'GN',
        GP = 'GP',
        GQ = 'GQ',
        GR = 'GR',
        GS = 'GS',
        GT = 'GT',
        GU = 'GU',
        GW = 'GW',
        GY = 'GY',
        HK = 'HK',
        HM = 'HM',
        HN = 'HN',
        HR = 'HR',
        HT = 'HT',
        HU = 'HU',
        IC = 'IC',
        ID = 'ID',
        IE = 'IE',
        IL = 'IL',
        IM = 'IM',
        IN = 'IN',
        IO = 'IO',
        IQ = 'IQ',
        IR = 'IR',
        IS = 'IS',
        IT = 'IT',
        JE = 'JE',
        JM = 'JM',
        JO = 'JO',
        JP = 'JP',
        KE = 'KE',
        KG = 'KG',
        KH = 'KH',
        KI = 'KI',
        KM = 'KM',
        KN = 'KN',
        KP = 'KP',
        KR = 'KR',
        KW = 'KW',
        KY = 'KY',
        KZ = 'KZ',
        LA = 'LA',
        LB = 'LB',
        LC = 'LC',
        LI = 'LI',
        LK = 'LK',
        LR = 'LR',
        LS = 'LS',
        LT = 'LT',
        LU = 'LU',
        LV = 'LV',
        LY = 'LY',
        MA = 'MA',
        MC = 'MC',
        MD = 'MD',
        ME = 'ME',
        MF = 'MF',
        MG = 'MG',
        MH = 'MH',
        MK = 'MK',
        ML = 'ML',
        MM = 'MM',
        MN = 'MN',
        MO = 'MO',
        MP = 'MP',
        MQ = 'MQ',
        MR = 'MR',
        MS = 'MS',
        MT = 'MT',
        MU = 'MU',
        MV = 'MV',
        MW = 'MW',
        MX = 'MX',
        MY = 'MY',
        MZ = 'MZ',
        NA = 'NA',
        NC = 'NC',
        NE = 'NE',
        NF = 'NF',
        NG = 'NG',
        NI = 'NI',
        NL = 'NL',
        NO = 'NO',
        NP = 'NP',
        NR = 'NR',
        NT = 'NT',
        NU = 'NU',
        NZ = 'NZ',
        OM = 'OM',
        PA = 'PA',
        PE = 'PE',
        PF = 'PF',
        PG = 'PG',
        PH = 'PH',
        PK = 'PK',
        PL = 'PL',
        PM = 'PM',
        PN = 'PN',
        PR = 'PR',
        PS = 'PS',
        PT = 'PT',
        PW = 'PW',
        PY = 'PY',
        QA = 'QA',
        RE = 'RE',
        RO = 'RO',
        RS = 'RS',
        RU = 'RU',
        RW = 'RW',
        SA = 'SA',
        SB = 'SB',
        SC = 'SC',
        SD = 'SD',
        SE = 'SE',
        SF = 'SF',
        SG = 'SG',
        SH = 'SH',
        SI = 'SI',
        SJ = 'SJ',
        SK = 'SK',
        SL = 'SL',
        SM = 'SM',
        SN = 'SN',
        SO = 'SO',
        SR = 'SR',
        SS = 'SS',
        ST = 'ST',
        SU = 'SU',
        SV = 'SV',
        SX = 'SX',
        SY = 'SY',
        SZ = 'SZ',
        TA = 'TA',
        TC = 'TC',
        TD = 'TD',
        TF = 'TF',
        TG = 'TG',
        TH = 'TH',
        TJ = 'TJ',
        TK = 'TK',
        TL = 'TL',
        TM = 'TM',
        TN = 'TN',
        TO = 'TO',
        TP = 'TP',
        TR = 'TR',
        TT = 'TT',
        TV = 'TV',
        TW = 'TW',
        TZ = 'TZ',
        UA = 'UA',
        UG = 'UG',
        UK = 'UK',
        UM = 'UM',
        US = 'US',
        UY = 'UY',
        UZ = 'UZ',
        VA = 'VA',
        VC = 'VC',
        VE = 'VE',
        VG = 'VG',
        VI = 'VI',
        VN = 'VN',
        VU = 'VU',
        WF = 'WF',
        WS = 'WS',
        XI = 'XI',
        XU = 'XU',
        XK = 'XK',
        YE = 'YE',
        YT = 'YT',
        YU = 'YU',
        ZA = 'ZA',
        ZM = 'ZM',
        ZR = 'ZR',
        ZW = 'ZW',
    }


}

