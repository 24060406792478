/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { JsonNode } from './JsonNode';

/**
 * Describes a single task item.
 */
export type TaskResponse = {
    /**
     * Identifies the task for REST operations. Can be absent if the task is transient and cannot be directly modified.
     */
    id?: string;
    /**
     * The current status of this task.
     */
    status: TaskResponse.status;
    /**
     * An identifier for the program that this task belongs to.
     */
    program: string;
    /**
     * An identifier for the group that this task belongs to (inside the program).
     */
    group: string;
    /**
     * An identifier for the task itself (inside the group).
     */
    slug: string;
    /**
     * The date/time when this task will become available.
     */
    availableFrom?: string;
    /**
     * The date/time when this task will no longer be available.
     */
    availableTo?: string;
    /**
     * The date/time when this task was started.
     */
    startedAt?: string;
    /**
     * The date/time when this task was completed.
     */
    completedAt?: string;
    additionalData: JsonNode;
};

export namespace TaskResponse {

    /**
     * The current status of this task.
     */
    export enum status {
        LOCKED = 'LOCKED',
        AVAILABLE = 'AVAILABLE',
        IN_PROGRESS = 'IN_PROGRESS',
        COMPLETED = 'COMPLETED',
        SKIPPED = 'SKIPPED',
    }


}

