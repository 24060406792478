/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DefinePreferredPharmacyRequest = {
    /**
     * The id of pharmacy in provider
     */
    providerPharmacyId: string;
    /**
     * The pharmacy provider, f.e. : DOSESPOT
     */
    providerType: DefinePreferredPharmacyRequest.providerType;
};

export namespace DefinePreferredPharmacyRequest {

    /**
     * The pharmacy provider, f.e. : DOSESPOT
     */
    export enum providerType {
        DOSESPOT = 'DOSESPOT',
    }


}

