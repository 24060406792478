/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data for updating a lifeline item.
 */
export type AdminUpdateLifelineItemRequestObject = {
    /**
     * The new item content.
     */
    payload: any;
    /**
     * The reason for the update of the item.
     */
    reason: AdminUpdateLifelineItemRequestObject.reason;
};

export namespace AdminUpdateLifelineItemRequestObject {

    /**
     * The reason for the update of the item.
     */
    export enum reason {
        CORRECTED_ERROR = 'CORRECTED_ERROR',
    }


}

