/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the item of shipment Contains all the data related to the given shipment item - sku, type
 */
export type ShipmentItemResponse = {
    /**
     * Id of the shipment item
     */
    id: string;
    sku: string;
    /**
     * Quantity of product/good
     */
    quantity: number;
    /**
     * Type of product/good
     */
    type: ShipmentItemResponse.type;
    /**
     * Reference to product shipment cause , f.e Subscription, Fill request
     */
    reference: string;
};

export namespace ShipmentItemResponse {

    /**
     * Type of product/good
     */
    export enum type {
        MEDICATION = 'MEDICATION',
        LAB_TEST_KIT = 'LAB_TEST_KIT',
        TEST_STRIP = 'TEST_STRIP',
        GLUCOMETER = 'GLUCOMETER',
        LANCET = 'LANCET',
        COMPLEMENT = 'COMPLEMENT',
        WELCOME_KIT = 'WELCOME_KIT',
        BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
        CONNECTED_WEIGHT_SCALE = 'CONNECTED_WEIGHT_SCALE',
    }


}

