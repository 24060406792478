/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information required to initiate a shipment.
 * @deprecated
 */
export type InitiateShipmentRequest = {
    /**
     * The user ID for whom a shipment should be initiated.
     */
    userId: string;
    /**
     * The shipment priority.
     */
    priority?: InitiateShipmentRequest.priority;
    /**
     * Override the item type for stored shipments.
     */
    overrideShipmentItemType?: InitiateShipmentRequest.overrideShipmentItemType;
    /**
     * A set of purchase item IDs that should be included in the shipment. Absent or empty list means all items will be shipped.
     */
    includePurchaseItemIds?: Array<string>;
};

export namespace InitiateShipmentRequest {

    /**
     * The shipment priority.
     */
    export enum priority {
        HIGHEST = 'HIGHEST',
        HIGH = 'HIGH',
        REGULAR = 'REGULAR',
    }

    /**
     * Override the item type for stored shipments.
     */
    export enum overrideShipmentItemType {
        MEDICATION = 'MEDICATION',
        LAB_TEST_KIT = 'LAB_TEST_KIT',
        TEST_STRIP = 'TEST_STRIP',
        GLUCOMETER = 'GLUCOMETER',
        LANCET = 'LANCET',
        COMPLEMENT = 'COMPLEMENT',
        WELCOME_KIT = 'WELCOME_KIT',
        BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
        CONNECTED_WEIGHT_SCALE = 'CONNECTED_WEIGHT_SCALE',
    }


}

