/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Details about individual tests which are part of a lab order
 */
export type LabOrderTestAdminResponse = {
    /**
     * The actual lab test.
     */
    labTest: LabOrderTestAdminResponse.labTest;
    /**
     * A set of reasons why this lab test is scheduled.
     */
    reasons: Array<string>;
    /**
     * The trigger type (scheduled or automatic)
     */
    triggerType?: LabOrderTestAdminResponse.triggerType;
    /**
     * The user who has scheduled the lab test.
     */
    requestingUserId?: string;
};

export namespace LabOrderTestAdminResponse {

    /**
     * The actual lab test.
     */
    export enum labTest {
        A1C = 'A1C',
        ALANINE_TRANSAMINASE = 'ALANINE_TRANSAMINASE',
        ASPARTATE_AMINOTRANSFERASE = 'ASPARTATE_AMINOTRANSFERASE',
        BMP = 'BMP',
        CMP = 'CMP',
        CREATININE = 'CREATININE',
        C_PEPTIDE_SERUM = 'C_PEPTIDE_SERUM',
        DIABETES_PANEL = 'DIABETES_PANEL',
        FRUCTOSAMINE = 'FRUCTOSAMINE',
        GAD65 = 'GAD65',
        GLUCOSE = 'GLUCOSE',
        HEPATIC_FUNCTION_PANEL = 'HEPATIC_FUNCTION_PANEL',
        LIPIDS = 'LIPIDS',
        POTASSIUM = 'POTASSIUM',
        SODIUM = 'SODIUM',
        THYROID_STIMULATING_HORMONE = 'THYROID_STIMULATING_HORMONE',
        TOTAL_CHOLESTEROL = 'TOTAL_CHOLESTEROL',
        TRIGLYCERIDES = 'TRIGLYCERIDES',
        URINARY_ALBUMIN_TO_CREATININE_RATIO = 'URINARY_ALBUMIN_TO_CREATININE_RATIO',
    }

    /**
     * The trigger type (scheduled or automatic)
     */
    export enum triggerType {
        MANUAL = 'MANUAL',
        AUTOMATIC = 'AUTOMATIC',
    }


}

