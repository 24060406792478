/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data that is needed to document a lab order, which is handled by a non-integrated lab provider.
 */
export type DocumentLabOrderRequest = {
    /**
     * The user ID
     */
    userId: string;
    /**
     * The trigger date for the lab order. Must be in the past.
     */
    triggerAt: string;
    /**
     * The lab order provider type
     */
    labOrderProviderType: DocumentLabOrderRequest.labOrderProviderType;
    /**
     * Set of lab tests, associated with reasons, that overwrite the ones currently in place
     */
    testsWithReasons: Record<string, Array<string>>;
    /**
     * The reference id of the lab order in the lab order provider
     */
    providerOrderReferenceId?: string;
    /**
     * If true, the patient organized and covered the test themselves.
     */
    selfOrganized?: boolean;
};

export namespace DocumentLabOrderRequest {

    /**
     * The lab order provider type
     */
    export enum labOrderProviderType {
        TRUEPILL = 'TRUEPILL',
        BIO_REFERENCE_LAB = 'BIO_REFERENCE_LAB',
        PATIENT = 'PATIENT',
        ZENDESK = 'ZENDESK',
        GETLABS = 'GETLABS',
        QUEST = 'QUEST',
        LABCORP = 'LABCORP',
        EVERLYWELL = 'EVERLYWELL',
        TASSO = 'TASSO',
    }


}

