/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data required to de-register a push notification token.
 */
export type DeregisterTokenRequest = {
    /**
     * The app ID of the associated application.
     */
    applicationId: string;
    /**
     * The platform for which the token is valid.
     */
    pushPlatform: DeregisterTokenRequest.pushPlatform;
    /**
     * The actual token.
     */
    token: string;
};

export namespace DeregisterTokenRequest {

    /**
     * The platform for which the token is valid.
     */
    export enum pushPlatform {
        APNS = 'APNS',
        FCM = 'FCM',
    }


}

