/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Provides the information to update a task.
 */
export type UpdateTaskRequest = {
    /**
     * The task status.
     */
    status: UpdateTaskRequest.status;
};

export namespace UpdateTaskRequest {

    /**
     * The task status.
     */
    export enum status {
        IN_PROGRESS = 'IN_PROGRESS',
        COMPLETED = 'COMPLETED',
        SKIPPED = 'SKIPPED',
    }


}

