/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AdminProviderSummaryResponse } from './AdminProviderSummaryResponse';
import type { AdminUserSummaryResponse } from './AdminUserSummaryResponse';
import type { ConsultationDataProviderSpecificsAdminResponse } from './ConsultationDataProviderSpecificsAdminResponse';

/**
 * Represents consultation's data.
 */
export type AdminConsultationResponse = {
    /**
     * Consultation's id.
     */
    id: string;
    patient?: AdminUserSummaryResponse;
    provider?: AdminProviderSummaryResponse;
    /**
     * Care team user id assigned to consultation.
     */
    careTeamUserId?: string;
    /**
     * Consultation's provider.
     */
    consultationProviderType: AdminConsultationResponse.consultationProviderType;
    consultationProviderSpecifics: ConsultationDataProviderSpecificsAdminResponse;
    /**
     * Consultation's status.
     */
    status: AdminConsultationResponse.status;
    /**
     * The date at which the consultation was created.
     */
    createdAt?: string;
    /**
     * Last update date.
     */
    lastUpdated: string;
    /**
     * Consultation's summary.
     */
    reasonSummary?: string;
    /**
     * Set of reasons.
     */
    reasons?: Array<'INITIAL_CONSULTATION' | 'FOLLOW_UP_CONSULTATION' | 'LABS_CONSULTATION' | 'ORDER_ONLY_CONSULTATION'>;
    /**
     * Consultation format
     */
    format: AdminConsultationResponse.format;
};

export namespace AdminConsultationResponse {

    /**
     * Consultation's provider.
     */
    export enum consultationProviderType {
        WHEEL = 'WHEEL',
        TRUEPILL = 'TRUEPILL',
        ZENDESK = 'ZENDESK',
        NINE_AM_HEALTH = 'NINE_AM_HEALTH',
    }

    /**
     * Consultation's status.
     */
    export enum status {
        DRAFT = 'DRAFT',
        ACTIVE = 'ACTIVE',
        COMPLETED = 'COMPLETED',
        ISSUE = 'ISSUE',
        REJECTED = 'REJECTED',
        CANCELLED = 'CANCELLED',
        WAITING_FOR_CARE_TEAM = 'WAITING_FOR_CARE_TEAM',
    }

    /**
     * Consultation format
     */
    export enum format {
        ASYNC = 'ASYNC',
        SYNC = 'SYNC',
    }


}

