/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data needed for adding an item to an existing subscription
 */
export type AdminSubscriptionAddItemRequest = {
    /**
     * The subscription ID
     */
    subscriptionId: string;
    sku?: string;
    /**
     * The item provider
     */
    provider: AdminSubscriptionAddItemRequest.provider;
    /**
     * The item type
     */
    typeName: AdminSubscriptionAddItemRequest.typeName;
    /**
     * Collection of metadata
     */
    metadata: Record<string, any>;
};

export namespace AdminSubscriptionAddItemRequest {

    /**
     * The item provider
     */
    export enum provider {
        OTC = 'OTC',
        TREATMENT_PLAN = 'TREATMENT_PLAN',
        PRESCRIPTION = 'PRESCRIPTION',
    }

    /**
     * The item type
     */
    export enum typeName {
        SUBSCRIPTION_PLAN_SUGGESTION = 'SubscriptionPlanSuggestion',
        LAB_TEST_SUGGESTION = 'LabTestSuggestion',
        MEDICATION_SUGGESTION = 'MedicationSuggestion',
        PRELIMINARY_MEDICATION_SUGGESTION = 'PreliminaryMedicationSuggestion',
        PRESCRIBED_MEDICATION = 'PrescribedMedication',
        DOCUMENTED_MEDICATION = 'DocumentedMedication',
        PRESCRIBED_SUPPLY = 'PrescribedSupply',
        OVER_THE_COUNTER_GLUCOMETER = 'OverTheCounterGlucometer',
        OVER_THE_COUNTER_LAB_TEST = 'OverTheCounterLabTest',
        OVER_THE_COUNTER_MICROLET_COLOR_LANCET_ITEM = 'OverTheCounterMicroletColorLancetItem',
        OVER_THE_COUNTER_STRIP_ITEM = 'OverTheCounterStripItem',
        SUBSCRIBED_DEVICE = 'SubscribedDevice',
    }


}

